import styled from "@emotion/styled";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { useOutletContext } from "react-router-dom";
import { getWithQueryApiServices } from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { ListBox } from "../../../styles/commonStyles";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
    position: "none",
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1.2%",
  boxSizing: "border-box",
  // display: "flex",
  // justifyContent: "space-between",
  // overflowX: "scroll"
}));

const CompanyBox = styled(Box)(({ theme }) => ({
  height: "600px",
  width: "300px",
  backgroundColor: "white",
  borderRadius: 8,
  padding: "12px 0",
  boxSizing: "border-box",
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#bdbdbd",
  // overflowY: "scroll"
}));

const CompanyTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
  borderBottomColor: "#bdbdbd",
}));

export const AccessList = ({
  userDetails,
  setUserDetails,
  addNewUser,
  setAddnewUser,
}) => {
  const [lockCategories, setLockCategories] = useState(false);
  const { tenantString } = useOutletContext();

  const { data: allUsersWithCategory } = useQuery({
    queryKey: ["allUsersWithCategory", tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_ALL_USER_WITH_CATEGORY,
        `pageNumber=1&selectedCompany=1${tenantString}`
      ),
  });

  const { data: allUserAccess } = useQuery({
    queryKey: ["allUserAccess", tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_ALL_ACCESS_OF_USER,
        `selectedCompany=1${tenantString}`
      ),
  });

  const enableDisableCompany = (companyID) => {
    let workData = [...userDetails?.accessList];
    const checkExist = workData?.some(
      (access) => access?.companyID === companyID
    );

    if (checkExist) {
      workData = workData?.filter((item) => item?.companyID !== companyID);
    } else {
      workData = [
        ...workData,
        { companyID, levelList: [...allUserAccess?.data], categoryList: [] },
      ];
    }

    setUserDetails({ ...userDetails, accessList: [...workData] });
  };

  const isCategoryLocked = (category, value) => {
    let workData = [...userDetails?.accessList];
    workData = workData?.map((item) => {
      let getCompany = { ...item };
      const origianlList = allUsersWithCategory?.data?.find(
        (data) => data?.companyID === item?.companyID
      );
      const isOriginalCategoryExist =
        origianlList?.categories?.includes(category);

      if (isOriginalCategoryExist) {
        const isCategoryExist = getCompany?.categoryList?.some(
          (cat) => cat === category
        );
        if (value) {
          if (!isCategoryExist) {
            getCompany = {
              ...getCompany,
              categoryList: [...getCompany?.categoryList, category],
            };
          }
        } else {
          if (isCategoryExist) {
            getCompany = {
              ...getCompany,
              categoryList: getCompany?.categoryList?.filter(
                (item) => item !== category
              ),
            };
          }
        }
      }

      return getCompany;
    });

    setUserDetails({ ...userDetails, accessList: [...workData] });
  };

  const categoryOnchange = (companyID, category, value) => {
    if (lockCategories) {
      return isCategoryLocked(category, value);
    }
    let workData = [...userDetails?.accessList];
    let getCompany = workData?.find((item) => item?.companyID === companyID);
    const isCategoryExist = getCompany?.categoryList?.some(
      (cat) => cat === category
    );

    if (isCategoryExist) {
      getCompany = {
        ...getCompany,
        categoryList: getCompany?.categoryList?.filter(
          (item) => item !== category
        ),
      };
    } else {
      getCompany = {
        ...getCompany,
        categoryList: [...getCompany?.categoryList, category],
      };
    }

    workData = workData?.map((item) => {
      if (item?.companyID === companyID) {
        return getCompany;
      }
      return item;
    });

    setUserDetails({ ...userDetails, accessList: [...workData] });
  };

  const isCategoryChecked = (companyID, category) => {
    let workData = [...userDetails?.accessList];
    let getCompany = workData?.find((item) => item?.companyID === companyID);
    return getCompany?.categoryList?.some((item) => item === category) || false;
  };

  const isCompanyChecked = (companyID) => {
    return userDetails?.accessList?.some(
      (access) => companyID === access?.companyID
    );
  };

  const handleMasterScroll = async (e, index) => {
    if (lockCategories) {
      let combineScroll = document.querySelectorAll(".combineScroll");
      let master = e.target;
      let height = master.scrollHeight - master.clientHeight;
      let percentage = (master.scrollTop / height) * 100;

      for (let i = 0; i < combineScroll.length; i++) {
        let slaveHeight =
          combineScroll[i].scrollHeight - combineScroll[i].clientHeight;
        let newSlavePosition = Math.ceil((percentage / 100) * slaveHeight);
        if (i !== index) {
          combineScroll[i].scroll({
            top: newSlavePosition,
          });
        }
      }
    }
  };

  return (
    <BoxContainer style={{ pointerEvents: !addNewUser ? "none" : "auto" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={lockCategories}
            onChange={() => {
              setLockCategories(!lockCategories);
            }}
          />
        }
        label={"Lock Categories"}
        style={{ paddingLeft: "24px", paddingBottom: 8 }}
      />
      <div style={{ padding: "0 20px", position: "relative" }}>
        {allUsersWithCategory?.data?.length && (
          <Carousel responsive={responsive}>
            {allUsersWithCategory?.data?.map((item, index) => (
              <CompanyBox>
                <CompanyTitle>
                  {item?.name}
                  <HeaderBox>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={isCompanyChecked(item?.companyID)}
                            onChange={() =>
                              enableDisableCompany(item?.companyID)
                            }
                            name="enableDisable"
                          />
                        }
                        label={
                          isCompanyChecked(item?.companyID)
                            ? "Enable"
                            : "Disabled"
                        }
                      />
                    </FormGroup>
                    <FormGroup style={{ padding: "8px 0" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={
                              item?.companyID === userDetails?.defaultcompany
                            }
                            onChange={() =>
                              setUserDetails({
                                ...userDetails,
                                defaultcompany: item?.companyID,
                              })
                            }
                            name="defaultcompany"
                          />
                        }
                        label="Set Default"
                      />
                    </FormGroup>
                  </HeaderBox>
                </CompanyTitle>
                <div
                  style={{
                    overflowY: "auto",
                    height: "100%",
                    pointerEvents: !isCompanyChecked(item?.companyID)
                      ? "none"
                      : "auto",
                    backgroundColor: !isCompanyChecked(item?.companyID)
                      ? "#f0f0f0"
                      : "white",
                    opacity: !isCompanyChecked(item?.companyID) ? "0.6" : "1",
                  }}
                  onScroll={(e) => handleMasterScroll(e, index)}
                  className="combineScroll"
                >
                  {item?.categories?.sort()?.map((category) => (
                    <ListBox>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCategoryChecked(
                              item?.companyID,
                              category
                            )}
                            onChange={(e) =>
                              categoryOnchange(
                                item?.companyID,
                                category,
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={category}
                        style={{ paddingLeft: "24px" }}
                      />
                    </ListBox>
                  ))}
                </div>
              </CompanyBox>
            ))}
          </Carousel>
        )}
      </div>
    </BoxContainer>
  );
};
