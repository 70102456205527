export const apiRoutes = {
  login: "login",

  // Admin
  ADMIN_LOGIN: "admin/login",
  GET_TENENT_LIST: "admin/tenents",

  // Company
  GET_TIMEZONES: "company/timezones/all",
  GET_COMPANY_LIST: "company",
  UPDATE_COMPANY: "company",
  CREATE_COMPANY: "company",
  DELETE_COMPANY: "company",
  UPDATE_DST: "company/dst",
  DELETE_COMPANY_USER: "company",
  ADD_USER_TO_COMPANY: "company/addUserToCompany",

  // Users
  GET_USERS_LIST: "user",
  GET_ALL_USERS_LIST: "user/all-users",
  GET_ALL_USER_WITH_CATEGORY: "company/category/all",
  GET_ALL_ACCESS_OF_USER: "user/all-access-pages",
  CREATE_USER: "user",
  SIGNUP_USER: "signup",
  UPDATE_USER: "user",
  GET_USER_DETAILS_BY_ID: "user",
  DELETE_USER_BY_ID: "user",
  UPDATE_USER_BY_ID: "user-Id",

  // Categories
  GET_CATEGOTY_LIST: "category",
  CREATE_CATEGORY: "category",
  UPDATE_CATEGORY: "category",
  DELETE_CATEGORY: "category",
  UPDATE_CATEGORY_CRITERIA: "category/criteria",
  COPY_CATEGORIES: "category/copyCategories",
  REMOVE_USER_FROM_CAT: "category/removeUserForCategory",
  ADD_USER_FROM_CAT: "category/addUserForCategory",
  GET_INVOICE_LIST: "invoice",
  PAY_INVOICE: "invoice/pay",
  SUBSCRIBE_INVOICE: "invoice/subscribe",
  GET_SUBSCRIPTION_LIST: "subscription",

  // Power Search
  POWER_SEARCH: "search/power/submit",
  QUICK_SEARCH: "search/quick/submit",

  PASSWORD_RESET: "user/password-reset",
};
