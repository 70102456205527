import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { IconButton, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  deletewithQueryApiServices,
  getWithQueryApiServices,
  putWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { ListBox, StyledTypography } from "../../../styles/commonStyles";
import { IconColor } from "../../../styles/constants";
import { successToast } from "../../../utils/helper";
import { CommonSearch } from "../../shared/CommonSearch";

export const CompanyUsers = ({ companyForm, addNewCompany }) => {
  const cookies = new Cookies();
  const defaultcompany = cookies.get("defaultcompany");
  const [searchUser, setSearchUser] = useState("");
  const { tenantString } = useOutletContext();

  const { data: usersData, refetch: refetchUserList } = useQuery({
    queryKey: ["usersData", companyForm, searchUser, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_USERS_LIST,
        `pageNumber=1&selectedCompany=${companyForm?.companyID}&search=${searchUser}${tenantString}`
      ),
    enabled: !!companyForm?.companyID,
  });

  const { data: allUsersList } = useQuery({
    queryKey: ["usersList", searchUser],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_ALL_USERS_LIST,
        `pageNumber=1&search=${searchUser}`
      ),
  });

  // const updateUserForCompany = useMutation({
  //   mutationKey: ["updateUsertoCompany"],
  //   mutationFn: (workData) => putWithQueryApiServices(apiRoutes.UPDATE_USER,
  //     `selectedCompany=${companyForm?.companyID}`
  //     , workData),
  //   onSuccess: () => {
  //     refetchUserList();
  //     successToast("User Added Successfully");
  //   },
  // });

  const addUserForCompany = useMutation({
    mutationKey: ["addUserForCompany"],
    mutationFn: (workData) =>
      putWithQueryApiServices(
        apiRoutes.ADD_USER_TO_COMPANY,
        `selectedCompany=${companyForm?.companyID}${tenantString}`,
        workData
      ),
    onSuccess: () => {
      refetchUserList();
      successToast("User Added Successfully");
    },
  });

  const removeUser = useMutation({
    mutationKey: ["removeUser"],
    mutationFn: (id) =>
      deletewithQueryApiServices(
        apiRoutes.DELETE_COMPANY_USER,
        `selectedCompany=${defaultcompany}${tenantString}`,
        id
      ),
    onSuccess: () => {
      refetchUserList();
      successToast("User removed from company");
    },
  });

  const { data: allUserAccess } = useQuery({
    queryKey: ["allUserAccess", tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_ALL_ACCESS_OF_USER,
        `selectedCompany=1${tenantString}`
      ),
  });

  const checkUserExist = (data) => {
    const getCompany = usersData?.data?.userList?.find(
      (item) => item?.userID === data?.userID
    );
    return getCompany?.levelList?.length ? true : false;
  };

  const addUsertoCompany = (data) => {
    let workData = { ...data };
    !workData?.levelList?.length && (workData.levelList = allUserAccess?.data);

    if (companyForm?.companyID) {
      addUserForCompany.mutate(workData);
    }
  };

  const removeUserForCompany = (data) => {
    if (companyForm?.companyID) {
      removeUser.mutate(`${companyForm?.companyID}/${data?.userID}`);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "4px 0",
        boxSizing: "border-box",
        borderRadius: 14,
        pointerEvents: !addNewCompany ? "none" : "auto",
        backgroundColor: !addNewCompany ? "#f0f0f0" : "white",
        opacity: !addNewCompany ? "0.6" : "1",
      }}
    >
      <div style={{ padding: "0 20px" }}>
        <StyledTypography>
          Add Users to Company {companyForm?.name}
        </StyledTypography>
        <CommonSearch
          style={{ width: "100%" }}
          label={"All Users"}
          value={searchUser}
          handleChange={(e) => setSearchUser(e.target.value)}
        />
      </div>
      <div
        style={{
          height: "57vh",
          overflowY: "auto",
          marginTop: 20,
        }}
      >
        {companyForm?.companyID || addNewCompany ? (
          <>
            {allUsersList?.data?.userList?.map((user, i) => (
              <ListBox
                style={{
                  padding: 10,
                  justifyContent: "normal",
                  backgroundColor: checkUserExist(user) ? "#deedf5" : "white",
                  borderRadius: 6,
                  paddingLeft: "22px",
                }}
              >
                {checkUserExist(user) ? (
                  <IconButton
                    style={{
                      padding: 0,
                      marginRight: 20,
                    }}
                    onClick={() => removeUserForCompany(user)}
                  >
                    <PersonRemoveIcon
                      style={{
                        color: IconColor,
                        fontSize: 26,
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{ padding: 0, marginRight: 20 }}
                    onClick={() => addUsertoCompany(user)}
                  >
                    <GroupAddIcon
                      style={{
                        color: IconColor,
                        fontSize: 26,
                      }}
                    />
                  </IconButton>
                )}

                <Typography>{user?.username} </Typography>
              </ListBox>
            ))}
          </>
        ) : (
          <StyledTypography style={{ textAlign: "center" }}>
            Select any company
          </StyledTypography>
        )}
      </div>
    </div>
  );
};
