import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from '@mui/icons-material/Tune';
import { IconButton, InputAdornment, Popover } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getWithQueryApiServices } from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { DateFilter } from "./DateFilter";


const Search = styled("div")(({ theme }) => ({
    position: "relative",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
    height: "30px",
    padding: 3,
    display: "flex",
    alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
    // height: "20px",
    // width: "20px",
    marginRight: 16
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    boxSizing: "border-box",
    paddingRight: "12px",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    },
    height: "30px",
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
    width: "100%",
    "& > div": {
        width: "100%",
        // top: 56
    }
}));


export const PowerSearch = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const cookies = new Cookies();
    const defaultcompany = cookies.get("defaultcompany");

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchValue, setSearchValue] = useState("")

    const handleTooltipClose = () => {
        setOpen(false);
        setAnchorEl(null)
    };

    const handleTooltipOpen = (e) => {
        setOpen(true);
        setAnchorEl(e.currentTarget);
    };

    const onSearchChange = (e) => {
        if (e.charCode === 13 && searchValue) {
            getQuickSearch()
        }
    }

    const clearSearch = () => {
        setSearchValue("")
        if (location.pathname === "/searchResult") {
            navigate(-1)
        }
    }

    const getQuickSearch = () => {
        getWithQueryApiServices(apiRoutes.QUICK_SEARCH, `selectedCompany=${defaultcompany}&searchQuery=${searchValue}`)
            .then(({ data }) => {
                navigate(ROUTE_PATHS.SEARCH_RESULT, { state: { data, searchValue } })
            }).catch((res) => { })
    }

    // const { data, refetch } = useMutation({
    //     mutationKey: ["getQuickSearch"],
    //     mutationFn: (workData) => postWithQueryApiServices(apiRoutes.QUICK_SEARCH,
    //         `selectedCompany=${defaultcompany}&searchQuery=${searchValue}`,
    //         workData,
    //     ),
    //     onSuccess: ({ data }) => {
    //         console.log('data: ', data);
    //         // successToast("User Created Successfully");
    //     },
    // });

    return (
        <Search
            style={{
                width: "36%",
                borderRadius: 4,
                borderColor: "#bdbdbd",
                borderWidth: 1,
                borderStyle: "solid",
            }}
        >
            <SearchIconWrapper>
                <SearchIcon style={{ color: "#bdbdbd" }} />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                name="searchValue"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => onSearchChange(e)}
                autoComplete="off"
                endAdornment={

                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={clearSearch}
                        >
                            {searchValue && <StyledCancelIcon />}
                        </IconButton>

                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleTooltipOpen}
                        >
                            <TuneIcon />
                        </IconButton>
                    </InputAdornment>



                }
            />

            <StyledPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleTooltipClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateFilter searchValue={searchValue} setSearchValue={setSearchValue} setOpen={setOpen} />
            </StyledPopover>
        </Search>
    )
}
