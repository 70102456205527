import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  deleteApiServices,
  getApiServices,
  getWithQueryApiServices,
  putWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { companyFormInitVal } from "../../../constants";
import { SplitContainer, StyledHeader } from "../../../styles/commonStyles";
import { successToast } from "../../../utils/helper";
import { FullFeaturedCrudGrid } from "../../shared/CommonTable";
import { ParentPageWrapper } from "../../shared/ParentPageWrapper";
import { CompanyUsers } from "./CompanyUsers";

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1%",
  boxSizing: "border-box",
}));

const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
  marginLeft: "24px",
}));

const ActionItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "36%",
  marginRight: 18,
}));

export const Index = () => {
  const cookies = new Cookies();
  const defaultcompany = cookies.get("defaultcompany");
  const { globalCompanyRefetch, tenantString } = useOutletContext();

  const [searchCompany, setSearchCompany] = useState("");
  const [companyForm, setCompanyForm] = useState(companyFormInitVal);
  const [addNewCompany, setAddNewCompany] = useState(false);
  const { data: timezoneData } = useQuery({
    queryKey: ["timezoneList"],
    queryFn: () => getApiServices(apiRoutes.GET_TIMEZONES),
  });

  const columns = [
    { field: "name", headerName: "Name", flex: 1, editable: true },
    {
      field: "timezone",
      headerName: "Expiration",
      align: "left",
      headerAlign: "left",
      editable: true,
      flex: 1,
      type: "singleSelect",
      valueOptions: timezoneData?.data.map((_) => _.fullname),
    },
    {
      field: "ip",
      headerName: "Security Settings",
      align: "left",
      headerAlign: "left",
      flex: 1,
      editable: true,
    },
    {
      field: "dst",
      headerName: "Observe Daylight Savings",
      editable: true,
      type: "boolean",
      flex: 1,
    },
  ];

  const copyCompanyDetails = (val) => {
    const { companyID, ...rest } = val;
    setCompanyForm({ ...rest, copyFromId: companyID, name: "" });
    setAddNewCompany(companyID ? true : false);
  };

  const handleCompanyCheckbox = (val) => {
    setCompanyForm({ ...val });
    setAddNewCompany(val?.companyID ? true : false);
  };

  const deleteCompany = useMutation({
    mutationKey: ["deleteCompanyDetails"],
    mutationFn: (companyID) =>
      deleteApiServices(apiRoutes.DELETE_COMPANY, companyID),
    onSuccess: () => {
      companyListRefetch();
      globalCompanyRefetch && globalCompanyRefetch();
      setCompanyForm(companyFormInitVal);
      successToast("Company Deleted!");
    },
  });

  const { data, refetch: companyListRefetch } = useQuery({
    queryKey: ["companyLists", searchCompany, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_COMPANY_LIST,
        `pageNumber=1&search=${searchCompany}${tenantString}`
      ),
  });

  const updateCompanyStatus = useMutation({
    mutationKey: ["updateCompanyStatus"],
    mutationFn: (workData) =>
      putWithQueryApiServices(
        apiRoutes.UPDATE_DST,
        `selectedCompany=${defaultcompany}${tenantString}`,
        workData
      ),
    onSuccess: () => {
      companyListRefetch();
      globalCompanyRefetch && globalCompanyRefetch();
      successToast("Status Updated Successfully");
    },
  });

  return (
    <ParentPageWrapper>
      <BoxContainer>
        <StyledHeader>Companies</StyledHeader>
        <ParentContainer>
          <SplitContainer
            style={{ width: "60%", backgroundColor: "transparent" }}
          >
            <div style={{ height: "80vh" }}>
              <div
                style={{
                  backgroundColor: "white",
                  height: "92%",
                  borderRadius: 10,
                }}
              >
                {/* <ActionContainer>
                  <CommonSearch
                    value={searchCompany}
                    handleChange={(e) => setSearchCompany(e.target.value)}
                    style={{
                      margin: "2% 0 2% 3%",
                    }}
                  />
                  <Button
                    startIcon={<AddOutlinedIcon />}
                    variant="contained"
                    style={{ marginRight: "4%" }}
                  >
                    Add New Company
                  </Button>

                  <StyledTypography style={{ marginRight: "12%" }}>
                    Actions
                  </StyledTypography>
                </ActionContainer> */}
                <div
                  style={{
                    // overflowY: "auto",
                    height: "86%",
                  }}
                >
                  {data?.data?.list?.length > 0 ? (
                    <FullFeaturedCrudGrid
                      initialRows={data?.data?.list}
                      columns={columns}
                      label="company"
                      handleSelection={handleCompanyCheckbox}
                      search={searchCompany}
                      setSearch={setSearchCompany}
                      apiUrl={apiRoutes.UPDATE_COMPANY}
                      refetch={companyListRefetch}
                      initialFormValue={companyFormInitVal}
                    ></FullFeaturedCrudGrid>
                  ) : (
                    <div>no data</div>
                  )}

                  {/* <FormGroup>
                    {data?.data?.list?.map((item, i) => (
                      <ListBox>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item?.companyID === companyForm?.companyID}
                              onChange={() =>
                                item?.companyID === companyForm?.companyID
                                  ? handleCompanyCheckbox({})
                                  : handleCompanyCheckbox(item)
                              }
                            />
                          }
                          label={item?.name}
                          style={{ marginLeft: "12px" }}
                        />
                        <ActionItemContainer>
                          <Switch
                            checked={item?.dst === 1}
                            onChange={(e) => updateCompanyStatus.mutate({ companyId: item?.companyID, dst: e.target.checked ? 1 : 0 })}
                          />
                          <IconButton
                            onClick={() =>
                              item?.companyID &&
                              deleteCompany.mutate(item?.companyID)
                            }
                          >
                            <DeleteOutlineIcon
                              style={{ color: IconColor, fontSize: 26 }}
                            />
                          </IconButton>
                          <IconButton onClick={() => copyCompanyDetails(item)}>
                            <AddToPhotosOutlinedIcon
                              style={{ color: IconColor, fontSize: 26 }}
                            />
                          </IconButton>
                        </ActionItemContainer>
                      </ListBox>
                    ))}
                  </FormGroup> */}
                </div>
              </div>
            </div>
          </SplitContainer>
          {/* <SplitContainer
            style={{
              width: "25%",
              cursor: addNewCompany ? "auto" : "not-allowed",
              height: "80vh",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                height: "92%",
                backgroundColor: "white",
                boxSizing: "border-box",
                borderRadius: 14,
              }}
            >
              <CompanyForm
                addNewCompany={addNewCompany}
                setAddNewCompany={setAddNewCompany}
                companyForm={companyForm}
                setCompanyForm={setCompanyForm}
                companyListRefetch={companyListRefetch}
              />

              <Button
                variant="contained"
                startIcon={<AddOutlinedIcon />}
                onClick={() => {
                  setCompanyForm(companyFormInitVal);
                  setAddNewCompany(true);
                }}
                style={{ position: "absolute", bottom: 0, marginLeft: "24px" }}
              >
                Add New Company
              </Button>
            </div>
          </SplitContainer> */}

          <SplitContainer
            style={{
              cursor: addNewCompany ? "auto" : "not-allowed",
              backgroundColor: "transparent",
              height: "80vh",
            }}
          >
            <div
              style={{
                height: "92%",
                backgroundColor: "white",
                boxSizing: "border-box",
                borderRadius: 14,
              }}
            >
              <CompanyUsers
                companyForm={companyForm}
                addNewCompany={addNewCompany}
              />
            </div>
          </SplitContainer>
        </ParentContainer>
      </BoxContainer>
    </ParentPageWrapper>
  );
};
