import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const PaymentForm = ({
  invoiceID,
  onSubmit,
  allowedMethods = ["card", "ach"],
}) => {
  const [paymentType, setPaymentType] = useState("card");
  const [formData, setFormData] = useState({
    card: {
      number: "",
      expiration_date: "",
      cvv: "",
    },
    ach: {
      sec_code: "",
      account_number: "",
      routing_number: "",
      account_type: "checking",
    },
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Split the name to get the payment type (card or ach) and the field name
    const [paymentType, field] = name.split(".");

    setFormData((prevState) => ({
      ...prevState,
      [paymentType]: {
        ...prevState[paymentType],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    setPaymentType(allowedMethods[0]);
  }, [allowedMethods]);

  const validateForm = () => {
    const newErrors = {};

    if (paymentType === "card") {
      // Card number validation (non-strict mode)
      const strippedCardNumber = formData.card.number.replace(/\D/g, "");
      if (strippedCardNumber.length < 13 || strippedCardNumber.length > 19) {
        newErrors.number = "Card number should be between 13 and 19 digits";
        // } else if (!luhnCheck(strippedCardNumber)) {
        //   newErrors.number = "Invalid card number";
      }

      // Expiration date validation
      if (!/^\d{2}\/\d{2}$/.test(formData.card.expiration_date)) {
        newErrors.expiration_date = "Expiration date must be in MM/YY format";
      } else {
        const [month, year] = formData.card.expiration_date.split("/");
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;

        if (parseInt(month) < 1 || parseInt(month) > 12) {
          newErrors.expiration_date = "Invalid month";
        } else if (
          parseInt(year) < currentYear ||
          (parseInt(year) === currentYear && parseInt(month) < currentMonth)
        ) {
          newErrors.expiration_date = "Card has expired";
        }
      }

      // CVV validation
      if (!/^\d{3,4}$/.test(formData.card.cvv)) {
        newErrors.cvv = "CVV must be 3 or 4 digits";
      }
    } else {
      // ACH validation (unchanged)
      if (!/^\d{9,17}$/.test(formData.ach.account_number)) {
        newErrors.account_number = "Account number must be 9-17 digits";
      }
      if (!/^\d{9}$/.test(formData.ach.routing_number)) {
        newErrors.routing_number = "Routing number must be 9 digits";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit({
        invoiceID,
        formData: { [paymentType]: formData[paymentType] },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl component="fieldset" margin="normal">
        {allowedMethods.length > 1 && (
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Payment Method</FormLabel>
            <RadioGroup
              row
              name="paymentType"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              {allowedMethods.includes("card") && (
                <FormControlLabel
                  value="card"
                  control={<Radio />}
                  label="Credit Card"
                />
              )}
              {allowedMethods.includes("ach") && (
                <FormControlLabel value="ach" control={<Radio />} label="ACH" />
              )}
            </RadioGroup>
          </FormControl>
        )}
      </FormControl>

      {paymentType === "card" ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Card Number"
              name="card.number"
              value={formData.card.number}
              onChange={handleInputChange}
              error={!!errors.number}
              helperText={errors.number}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Expiration Date (MM/YY)"
              name="card.expiration_date"
              value={formData.card.expiration_date}
              onChange={handleInputChange}
              error={!!errors.expiration_date}
              helperText={errors.expiration_date}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="CVV"
              name="card.cvv"
              value={formData.card.cvv}
              onChange={handleInputChange}
              error={!!errors.cvv}
              helperText={errors.cvv}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="ach_sec_code_label">SEC Code</InputLabel>
              <Select
                fullWidth
                label="SEC Code"
                value={formData.ach.sec_code}
                error={!!errors.sec_code}
                helperText={errors.sec_code}
                name="ach.sec_code"
                onChange={handleInputChange}
                labelId="ach_sec_code_label"
                id="ach_sec_code"
              >
                {/* ["web", "ppd", "ccd", "tel"] */}
                {["web", "ppd", "ccd"].map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Account Number"
              name="ach.account_number"
              value={formData.ach.account_number}
              onChange={handleInputChange}
              error={!!errors.account_number}
              helperText={errors.account_number}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Routing Number"
              name="ach.routing_number"
              value={formData.ach.routing_number}
              onChange={handleInputChange}
              error={!!errors.routing_number}
              helperText={errors.routing_number}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Account Type</FormLabel>
              <RadioGroup
                row
                name="ach.account_type"
                value={formData.ach.account_type}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="checking"
                  control={<Radio />}
                  label="Checking"
                />
                <FormControlLabel
                  value="savings"
                  control={<Radio />}
                  label="Savings"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: "20px" }}
      >
        Submit Payment
      </Button>
    </form>
  );
};

export default PaymentForm;
