import styled from "@emotion/styled";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { StyledHeader } from "../../../styles/commonStyles";
import { ParentPageWrapper } from "../../shared/ParentPageWrapper";

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "2%",
  boxSizing: "border-box",
  overflowY: "scroll",
  height: "90vh",
}));

const HeaderCell = styled(Box)(({ theme }) => ({
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid gray",
  width: "100%",
  fontWeight: "bold",
}));

const DataCell = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid gray",
  width: "100%",
}));

export const SearchResult = () => {
  const location = useLocation();
  const data = location?.state?.data || [];

  const result = data?.reduce((acc, obj) => {
    const companyName = obj.companyName;
    if (!acc[companyName]) {
      acc[companyName] = [];
    }

    acc[companyName].push(obj);
    return acc;
  }, {});

  const resultArray = Object.values(result);

  const renderDate = (date) => {
    return date
      ? new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "";
  };

  return (
    <ParentPageWrapper>
      <BoxContainer>
        <StyledHeader>
          Viewing {data?.length} records of '{location?.state?.searchValue}'
        </StyledHeader>
        {resultArray?.length ? (
          resultArray?.map((categories) => (
            <Paper sx={{ overflow: "hidden", marginBottom: 4 }} elevation={8}>
              <Box
                sx={{
                  textAlign: "center",
                  padding: 2,
                  fontWeight: "bold",
                  backgroundColor: "#d9d9d9",
                }}
              >
                {categories?.[0]?.companyName}{" "}
              </Box>
              {categories?.map((item) => (
                <>
                  <Box
                    sx={{
                      textAlign: "left",
                      padding: 2,
                      backgroundColor: "#f1f1f1",
                      border: "1px solid grey",
                      fontWeight: "bold",
                    }}
                  >
                    {item?.category}{" "}
                  </Box>
                  <Grid container>
                    <Grid item xs={7} style={{ display: "flex" }}>
                      <HeaderCell>First Name</HeaderCell>
                      <HeaderCell>Last Name</HeaderCell>
                      <HeaderCell>Notes</HeaderCell>
                      <HeaderCell>ID</HeaderCell>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex" }}>
                      <HeaderCell>Document Dates</HeaderCell>
                    </Grid>
                    <Grid item xs={7} style={{ display: "flex" }}>
                      <DataCell> {item?.username}</DataCell>
                      <DataCell>{""} </DataCell>
                      <DataCell>{item?.note} </DataCell>
                      <DataCell> {item?.pictureID}</DataCell>
                    </Grid>
                    <Grid item xs={5}>
                      <Box style={{ display: "flex", height: 50 }}>
                        <DataCell> </DataCell>
                        <DataCell>Document Date</DataCell>
                      </Box>
                      <Box style={{ display: "flex", height: 50 }}>
                        <DataCell>
                          <FolderCopyIcon
                            style={{ margin: "0 10", color: "gray" }}
                          />
                          <PictureAsPdfIcon
                            style={{ margin: "0 8", color: "gray" }}
                          />
                          <ShareIcon style={{ margin: "0 8", color: "gray" }} />
                        </DataCell>
                        <DataCell>
                          {renderDate(item?.pictureDate || "")}
                        </DataCell>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Paper>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <StyledHeader style={{ fontSize: 28 }}>No Data Found</StyledHeader>
          </Box>
        )}
      </BoxContainer>
    </ParentPageWrapper>
  );
};
