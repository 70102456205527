import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import { getWithQueryApiServices } from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { companyFormInitVal } from "../../../constants";
import { SplitContainer, StyledHeader } from "../../../styles/commonStyles";
import { FullFeaturedCrudGrid } from "../../shared/CommonTable";
import { ParentPageWrapper } from "../../shared/ParentPageWrapper";
import { SubscriptionForm } from "./SubscriptionForm";

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1%",
  boxSizing: "border-box",
}));

const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const Subscription = () => {
  const { currentCompany, currentTenant, tenantString } = useOutletContext();
  const cookies = new Cookies();
  const userID = cookies.get("userId");
  const [companyForm, setCompanyForm] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [permission, setPermission] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      align: "left",
      headerAlign: "left",
      editable: false,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      align: "left",
      headerAlign: "left",
      editable: false,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Subscription amount",
      align: "left",
      headerAlign: "left",
      editable: false,
      flex: 0.5,
      type: "number",
    },
  ];

  const { data: usersData } = useQuery({
    queryKey: ["subscriptionList", currentCompany, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_USERS_LIST,
        `pageNumber=1&selectedCompany=${currentCompany}${tenantString}`
      ),
    enabled: !!currentCompany,
  });

  const handleCategoryCheckbox = (item) => {
    let copyValue = [...selectedCategories];

    if (copyValue.some((cat) => cat?.subscriptionID === item?.subscriptionID)) {
      copyValue = copyValue.filter(
        (val) => val?.subscriptionID !== item?.subscriptionID
      );
    } else {
      copyValue = [item];
    }

    setSelectedCategories([...copyValue]);

    if (copyValue?.length === 1) {
      setCategoryData(copyValue?.[0]);
      setAddNewCategory(true);
    } else {
      setCategoryData({});
      setAddNewCategory(false);
    }
  };

  const { data: companyData, refetch: companyListRefetch } = useQuery({
    queryKey: ["companyLists"],
    queryFn: () =>
      getWithQueryApiServices(apiRoutes.GET_COMPANY_LIST, `pageNumber=1`),
  });

  const { data: subscriptionData, refetch: categoryListRefetch } = useQuery({
    queryKey: [
      "subscriptionLists",
      currentCompany,
      searchCategory,
      tenantString,
    ],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_SUBSCRIPTION_LIST,
        `pageNumber=1${
          tenantString ? "" : `&selectedCompany=${currentCompany}`
        }&search=${searchCategory}${tenantString}`
      ),
    enabled: !!currentCompany,
  });

  const handleAddNew = () => {
    setCategoryData({});
    setAddNewCategory(true);
  };
  useEffect(() => {
    if (currentCompany) {
      setAddNewCategory(false);
      setSelectedCategories([]);
      setCompanyForm([]);
      setSearchCategory("");
      setCategoryData({});
    }
  }, [currentCompany]);

  useEffect(() => {
    const currentUser = usersData?.data?.userList?.find(
      (item) => item.userID === userID
    );
    if (!!currentTenant) {
      setPermission(true);
    } else {
      setPermission(
        currentUser?.levelList?.includes("Edit Subscription") ? true : false
      );
    }
  }, [usersData, userID, currentTenant]);
  return (
    <ParentPageWrapper>
      <BoxContainer>
        <StyledHeader>Subscriptions</StyledHeader>
        <ParentContainer>
          <SplitContainer
            style={{ backgroundColor: "transparent", width: "60%" }}
          >
            <div style={{ height: "80vh" }}>
              <div
                style={{
                  backgroundColor: "white",
                  height: "92%",
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    height: "82%",
                  }}
                >
                  <FullFeaturedCrudGrid
                    initialRows={subscriptionData?.data?.list}
                    columns={columns}
                    label="subscription"
                    handleAddNew={handleAddNew}
                    handleSelection={handleCategoryCheckbox}
                    search={searchCategory}
                    setSearch={setSearchCategory}
                    apiUrl={apiRoutes.CREATE_CATEGORY}
                    refetch={categoryListRefetch}
                    hideEdit={true}
                    initialFormValue={companyFormInitVal}
                    permission={permission}
                  ></FullFeaturedCrudGrid>
                </div>
              </div>
            </div>
          </SplitContainer>
          <SplitContainer
            style={{
              backgroundColor: "transparent",
              cursor: addNewCategory ? "auto" : "not-allowed",
            }}
          >
            <SubscriptionForm
              categoryListRefetch={categoryListRefetch}
              companyListRefetch={companyListRefetch}
              companyForm={companyForm}
              setCompanyForm={setCompanyForm}
              categoryData={categoryData}
              setCategoryData={setCategoryData}
              setAddNewCategory={setAddNewCategory}
              addNewCategory={addNewCategory}
              setSelectedCategories={setSelectedCategories}
              permission={permission}
            />
          </SplitContainer>
        </ParentContainer>
      </BoxContainer>
    </ParentPageWrapper>
  );
};
