import ApartmentIcon from "@mui/icons-material/Apartment";
import DescriptionIcon from "@mui/icons-material/Description";
import GridViewIcon from "@mui/icons-material/GridView";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { CustomSelectField } from "./CustomSelectField";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PublicIcon from "@mui/icons-material/Public";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ROUTE_PATHS } from "../../routes/routePath";
import { PowerSearch } from "../pages/powerSearch/PowerSearch";

const BaseNavItems = [
  {
    Icon: <GridViewIcon style={{ color: "#4b4b4c" }} />,
    label: "Categories",
    path: ROUTE_PATHS.CATEGORY_LIST,
  },
  {
    Icon: <ApartmentIcon style={{ color: "#4b4b4c" }} />,
    label: "Company",
    path: ROUTE_PATHS.COMPANY_LIST,
  },
  {
    Icon: <PeopleOutlineIcon style={{ color: "#4b4b4c" }} />,
    label: "User",
    path: ROUTE_PATHS.ADD_USERS,
  },
  {
    Icon: <QrCodeScannerIcon style={{ color: "#4b4b4c" }} />,
    label: "Scan",
  },
  {
    Icon: <DescriptionIcon style={{ color: "#4b4b4c" }} />,
    label: "Reporting",
  },
];

const InvoiceNavbarItems = [
  {
    Icon: <ReceiptLongIcon style={{ color: "#4b4b4c" }} />,
    label: "Billing",
    path: ROUTE_PATHS.INVOICE_LIST,
  },
  {
    Icon: <CardMembershipIcon style={{ color: "#4b4b4c" }} />,
    label: "Subscription",
    path: ROUTE_PATHS.SUBSCRIPTION_LIST,
  },
];

function Navbar({
  setCurrentCompany,
  currentCompany,
  companyList,
  currentTenant,
  setCurrentTenant,
  tenantList,
  globalCompanyRefetch,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [navbarItems, setNavbarItems] = React.useState(BaseNavItems);
  const cookies = new Cookies();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const companies = companyList?.filter((item) => item?.dst == 1);
  const tenants = tenantList?.map((item) => {
    item.name = item.tenantName;
    return item;
  });

  const logout = () => {
    const allCookies = cookies.getAll();
    Object.keys(allCookies).forEach((cookieName) => {
      cookies.remove(cookieName, { path: "/" });
    });
    navigate(ROUTE_PATHS.LOGIN);
  };

  React.useEffect(() => {
    if (currentTenant !== "") {
      setNavbarItems([...BaseNavItems, ...InvoiceNavbarItems]);
    }
  }, [currentTenant]);
  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <PublicIcon
            color="primary"
            style={{ fontSize: 50, marginRight: 20 }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {!!currentTenant ? (
              <CustomSelectField
                label="Current Tenant"
                style={{ width: "32%", marginRight: 24 }}
                name="currentTenant"
                inputValues={tenants || []}
                value={currentTenant || ""}
                accept="dbName"
                onChange={(e) => setCurrentTenant(e.target.value)}
              />
            ) : (
              <CustomSelectField
                label="Current Company"
                style={{ width: "32%" }}
                name="currentCompany"
                inputValues={companies || []}
                value={currentCompany || ""}
                accept="companyID"
                onChange={(e) => setCurrentCompany(e.target.value)}
              />
            )}
            <PowerSearch />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {navbarItems?.map(({ Icon, label, path }) => (
              <IconButton onClick={() => path && navigate(path)}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "0 16px",
                  }}
                >
                  {Icon}
                  <Typography
                    style={{
                      fontSize: 14,
                      color: "#4b4b4c",
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              </IconButton>
            ))}
            {/* <NotificationsNoneIcon
              style={{ fontSize: "30px", marginRight: 20, color: "#4b4b4c" }}
            /> */}
            <IconButton sx={{ p: 0 }} onClick={handleClick}>
              {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
              <Avatar>VR</Avatar>
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {!currentTenant && (
                <MenuItem
                  onClick={() => {
                    navigate(ROUTE_PATHS.INVOICE_LIST);
                    handleClose();
                  }}
                  sx={{ minWidth: 200 }}
                >
                  <ListItemIcon>
                    <ReceiptLongIcon />
                  </ListItemIcon>
                  Billing
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  logout();
                }}
                sx={{ minWidth: 200 }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
