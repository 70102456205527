import { Button, styled } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { postWithQueryApiServices } from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { setUserData } from "../../../redux/MainReducer";
import { store } from "../../../redux/Store";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { errorToast } from "../../../utils/helper";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100vh",
}));
const StyledInput = styled("input")(({ theme }) => ({
  padding: "1.1% 1.5%",
  width: "27%",
  borderRadius: "100px",
  fontSize: "18px",
  margin: ".8%",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#2c7aef",
  fontFamily: "Poppins",
  backgroundColor: "#F5F5F5",
  boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.1)",
  boxSizing: "border-box",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "1.1% 1.5%",
  width: "27%",
  borderRadius: "100px",
  color: "white",
  fontSize: "18px",
  margin: ".8%",
  backgroundColor: "#2c7aef",
  fontFamily: "Poppins",
}));

export const UserForm = ({
  userDetails,
  setUserDetails,
  addNewUser,
  setAddnewUser,
  userListRefetch,
  setSelectedUser,
  seelctedUser,
}) => {
  const cookies = new Cookies();
  const defaultcompany = cookies.get("defaultcompany", "1");
  const navigate = useNavigate();

  const formHandleChange = (name, value) => {
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const customValidation = () => {
    let result = "";
    let { accessList, defaultcompany, ...rest } = userDetails;

    !defaultcompany && (result = "Set any default company");
    accessList?.length === 0 &&
      (result = "Add companies and categories for the user");

    Object.keys(rest).forEach((key) => {
      if (rest[key] === "") {
        result = "Please fill all the fields";
      }
    });

    return result;
  };

  const createNewUser = useMutation({
    mutationKey: ["createNewUser"],
    mutationFn: (workData) =>
      postWithQueryApiServices(
        apiRoutes.SIGNUP_USER,
        `selectedCompany=1`,
        workData
      ),
    onSuccess: async ({ data }) => {
      cookies.set("token", data?.token, { path: "/" });
      cookies.set("userId", data?.userId, { path: "/" });
      cookies.set("userFullName", data?.userFullName, { path: "/" });
      cookies.set("defaultcompany", data?.defaultcompany, { path: "/" });
      store.dispatch(
        setUserData({
          ...data,
        })
      );
      navigate(ROUTE_PATHS.SUBSCRIBE);
    },
  });

  const handleSubmit = () => {
    // return
    const err = customValidation();
    if (err && userDetails?.userID) {
      return errorToast(err);
    }
    let workData = {
      ...userDetails,
    };
    !workData?.defaultcompany && (workData.defaultcompany = defaultcompany);
    createNewUser.mutate(workData);
  };

  return (
    <Container>
      {/* <StyledTypography>User Name</StyledTypography> */}
      <StyledInput
        name="username"
        type="text"
        placeholder="User Name"
        value={userDetails?.username}
        onChange={(e) => formHandleChange(e.target.name, e.target.value)}
      />
      <StyledInput
        name="title"
        type="email"
        placeholder="Email"
        value={userDetails?.title}
        onChange={(e) => formHandleChange(e.target.name, e.target.value)}
      />
      <StyledInput
        name="password"
        type="password"
        placeholder="Password"
        value={userDetails?.password}
        onChange={(e) => formHandleChange(e.target.name, e.target.value)}
      />
      <StyledInput
        name="ip"
        type="text"
        placeholder="Network Settings"
        value={userDetails?.ip}
        onChange={(e) => formHandleChange(e.target.name, e.target.value)}
      />
      <StyledInput
        name="tenantName"
        type="text"
        placeholder="Tenant Name"
        value={userDetails?.tenantName}
        onChange={(e) => formHandleChange(e.target.name, e.target.value)}
      />

      {/* <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name="issysadm"
              onChange={(e) =>
                formHandleChange(e.target.name, e.target.checked ? 1 : 0)
              }
              checked={userDetails?.issysadm == 1 ? true : false}
            />
          }
          label="Make System Admin"
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name="isActive"
              onChange={(e) =>
                formHandleChange(e.target.name, e.target.checked ? 1 : 0)
              }
              checked={userDetails?.isActive == 1 ? true : false}
            />
          }
          label="Active"
        />
      </FormGroup> */}

      <StyledButton variant="contained" onClick={handleSubmit}>
        Sign Up
      </StyledButton>
    </Container>
  );
};
