export const singinInitialValues = {
  username: "",
  password: "",
  tenantName: "",
};

export const adminSinginInitialValues = {
  username: "",
  password: "",
};

export const companyFormInitVal = {
  name: "",
  timezone: "",
  dst: 1,
  ip: "",
};

export const categoryFormInitVal = {
  name: "",
  expiration: "",
};

export const invoiceFormInitVal = {
  companyID: "",
  tax: 0,
  invoicedate: "",
  duedate: "",
  shippingcost: "",
  services: [],
};

export const subscriptionFormInitVal = {
  title: "",
  name: "",
  description: "",
  benfits: "",
  tax: 0,
  shippingcost: "",
  services: [],
};

export const userFormInitVal = {
  username: "",
  password: "",
  ip: "",
  isActive: 1,
  title: "",
  issysadm: 0,
  defaultcompany: "",
  accessList: [],
};

export const criteriaOptions = [
  {
    name: "type",
    value: "text",
    label: "Text",
  },
  {
    name: "type",
    value: "number",
    label: "Number",
  },
];

export const expirationValues = [
  {
    name: "1 Year",
    value: "1",
  },
  {
    name: "2 Years",
    value: "2",
  },
  {
    name: "3 Years",
    value: "3",
  },
  {
    name: "4 Years",
    value: "4",
  },
  {
    name: "5 Years",
    value: "5",
  },
  {
    name: "6 Years",
    value: "6",
  },
  {
    name: "7 Years",
    value: "7",
  },
  {
    name: "8 Years",
    value: "8",
  },
  {
    name: "9 Years",
    value: "9",
  },
  {
    name: "10 Years",
    value: "10",
  },
];

export const filerSearchInit = {
  isDocumentDate: false,
  isScannedDate: false,
  documentDateFrom: "",
  documentDateTo: "",
  scannedDateFrom: "",
  scannedDateTo: "",
  selectedCompanies: [],
};
