import React, { useState } from "react";
import { userFormInitVal } from "../../../constants";
import { UserForm } from "../user/UserForm";

export const Singup = () => {
  const [userDetails, setUserDetails] = useState(userFormInitVal);
  const [addNewUser, setAddnewUser] = useState(false);

  return (
    <UserForm
      userDetails={userDetails}
      setUserDetails={setUserDetails}
      addNewUser={addNewUser}
      setAddnewUser={setAddnewUser}
    />
  );
};
