export const ROUTE_PATHS = {
  LOGIN: "/",
  COMPANY_LIST: "/company/list",
  CATEGORY_LIST: "/category/list",
  INVOICE_LIST: "/invoice/list",
  SUBSCRIPTION_LIST: "/subscription/list",
  ADD_USERS: "users",
  SEARCH_RESULT: "searchResult",
  ADMIN_LOGIN: "/admin/login",
  SIGNUP: "/signup",
  SUBSCRIBE: "/subscribe",
};
