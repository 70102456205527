import axios from "axios";
import Cookies from "universal-cookie";
import { setToastMessage } from "../redux/MainReducer";
import { store } from "../redux/Store";
import { ROUTE_PATHS } from "../routes/routePath";

const cookies = new Cookies();

export const appApi = axios.create({
  // baseURL: "http://localhost:8787/api/",
  baseURL: "https://backend-worker.suvendra-ee.workers.dev/api/",

  responseType: "json",
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});

appApi.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = `Bearer ${cookies.get("token")}`;

    // store.dispatch(isLoading(true));
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

appApi.interceptors.response.use(
  (response) => {
    // store.dispatch(isLoading(false));
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      cookies.remove("token");
      cookies.remove("userId");
      cookies.remove("userFullName");
      cookies.remove("defaultcompany");
      cookies.remove("defaulttenant");

      store.dispatch(
        setToastMessage({
          severity: "error",
          message: "Session Expired!!",
          messageStatus: true,
        })
      );
      return (window.location = ROUTE_PATHS.LOGIN);
    }
    // store.dispatch(isLoading(false));
    return Promise.reject(error);
  }
);
