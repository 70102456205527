import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  getWithQueryApiServices,
  postWithQueryApiServices,
  putApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { successToast } from "../../../utils/helper";
import PaymentForm from "../../shared/PaymentForm";

const SubscriptionChoices = () => {
  const navigate = useNavigate();

  const cookies = new Cookies();
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("paid");
  const [payment, setPayment] = React.useState(null);
  const [selectedSubscription, setSelectedSubscription] = React.useState(null);
  const defaultcompany = cookies.get("defaultcompany");
  const userID = cookies.get("userId");
  const { data: subscriptionData } = useQuery({
    queryKey: ["subscriptionLists"],
    queryFn: () => getWithQueryApiServices(apiRoutes.GET_SUBSCRIPTION_LIST),
  });
  const startDate = moment().add(7, "days").format("MMMM D, YYYY");

  const processPayment = ({ invoiceID, formData }) => {
    setPayment(formData);
    setSelectedSubscription(invoiceID);
    createInvoice.mutate(invoiceID);
  };
  const createInvoice = useMutation({
    mutationKey: ["createInvoiceform"],
    mutationFn: (subscription) =>
      postWithQueryApiServices(
        apiRoutes.GET_INVOICE_LIST,
        `selectedCompany=${defaultcompany}`,
        {
          ...subscription,
          duedate:
            type === "free" ? moment().add(7, "days") : moment().toDate(),
          invoicedate: moment().toDate(),
          companyID: defaultcompany,
          invoiceitems: JSON.parse(subscription?.subscriptionitem),
        }
      ),
    onSuccess: ({ data }) => {
      createPayment.mutate({
        invoiceID: data.meta.last_row_id,
        formData: payment,
      });
    },
  });

  const createPayment = useMutation({
    mutationKey: ["payInvoiceform"],
    mutationFn: ({ invoiceID, formData }) =>
      postWithQueryApiServices(
        `${apiRoutes.PAY_INVOICE}/${invoiceID}`,
        `selectedCompany=${defaultcompany}`,
        { ...formData, type: type }
      ),
    onSuccess: () => {
      updateUser.mutate();
    },
  });

  const updateUser = useMutation({
    mutationKey: [`updateUserform`],
    mutationFn: () =>
      putApiServices(`${apiRoutes.UPDATE_USER}/${userID}/subscribe`, {
        subscriptionID: selectedSubscription.subscriptionID,
      }),
    onSuccess: () => {
      cookies.set("activeSubscription", true, {
        path: "/",
      });
      successToast("Subscribed Successfully");
      navigate(ROUTE_PATHS.COMPANY_LIST);
    },
  });

  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: theme.palette.grey[100] }}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 4,
          backgroundColor: theme.palette.primary.main,
          color: "white",
        }}
      >
        <Typography variant="h3" align="center" gutterBottom fontWeight="bold">
          Choose Your Perfect Plan
        </Typography>
        <Typography variant="h6" align="center">
          Select the subscription that best fits your needs
        </Typography>
      </Paper>
      <Grid container spacing={4} justifyContent="center">
        {subscriptionData?.data?.list?.map((subscription) => (
          <Grid item xs={12} sm={6} md={4} key={subscription.subscriptionID}>
            <Card
              raised
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "0.3s",
                "&:hover": { transform: "scale(1.03)" },
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      fontWeight="bold"
                      color={theme.palette.primary.main}
                    >
                      {subscription.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h5"
                      color={theme.palette.secondary.main}
                      sx={{ my: 1 }}
                    >
                      ${subscription.amount}
                      <Typography variant="caption">/mo</Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="subtitle2" color="text.secondary">
                  {subscription.description}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Tax: {subscription.tax}% | Shipping: $
                  {subscription.shippingcost}
                </Typography> */}
                <Typography
                  variant="h6"
                  sx={{ mt: 3, mb: 1, fontWeight: "bold" }}
                >
                  Benefits:
                </Typography>
                <List dense>
                  {subscription.benfits.split(",").map((benefit, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={benefit.trim()} />
                    </ListItem>
                  ))}
                </List>
                <Typography
                  variant="h6"
                  sx={{ mt: 3, mb: 1, fontWeight: "bold" }}
                >
                  Subscription Items:
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                  {JSON.parse(subscription.subscriptionitem).map((item) => (
                    <Chip
                      key={item.subscriptionitemID}
                      icon={<LocalOfferIcon />}
                      label={`${item.item} ${
                        item.quantity > 1 ? "- " + item.quantity : ""
                      }`}
                      color={item.recurring ? "primary" : "default"}
                      variant="outlined"
                    />
                  ))}
                </Box>
              </CardContent>
              <Box sx={{ p: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={() => {
                    setType("paid");
                    setOpen(subscription.subscriptionID);
                  }}
                  sx={{
                    py: 1.5,
                    fontWeight: "bold",
                    transition: "0.3s",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  Choose This Plan
                </Button>
                <Button
                  variant="text"
                  fullWidth
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setType("free");
                    setOpen(subscription.subscriptionID);
                  }}
                  sx={{
                    py: 0.5,
                  }}
                >
                  Start 7 day trail
                </Button>
                <Modal
                  open={open === subscription.subscriptionID}
                  aria-labelledby="payment-modal"
                  aria-describedby="payment-modal-description"
                  onClose={() => setOpen(false)}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <h2 id="payment-modal">Payment</h2>
                    {type === "free" && (
                      <Typography variant="caption">
                        Billing will start from{" "}
                        <Typography variant="caption" color="primary">
                          {startDate}
                        </Typography>
                      </Typography>
                    )}
                    <PaymentForm
                      invoiceID={subscription}
                      allowedMethods={
                        type === "free" ? ["ach"] : ["card", "ach"]
                      }
                      onSubmit={(arg) => processPayment(arg)}
                    />
                  </Box>
                </Modal>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SubscriptionChoices;
