import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  getWithQueryApiServices,
  putWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { ListBox, StyledTypography } from "../../../styles/commonStyles";
import { IconColor } from "../../../styles/constants";
import { successToast } from "../../../utils/helper";
import { CommonSearch } from "../../shared/CommonSearch";

export const CategoryUser = ({
  companyForm,
  setCompanyForm,
  companyData,
  addNewCategory,
  setAddNewCategory,
  setCategoryData,
  categoryData,
  selectedCategories,
}) => {
  const [searchUser, setSearchUser] = useState("");

  const { currentCompany, tenantString } = useOutletContext();
  const filteredCompany = companyData?.data?.list?.filter(
    (item) => item?.dst == 1 && item?.companyID !== currentCompany
  );

  const handleCompanyCheckbox = (val) => {
    let copyValue = [...companyForm];

    if (copyValue?.some((item) => item?.companyID === val?.companyID)) {
      copyValue = copyValue?.filter(
        (item) => item?.companyID !== val?.companyID
      );
    } else {
      copyValue.push(val);
    }
    setCompanyForm(copyValue);
    setAddNewCategory(false);
    setCategoryData({});
  };

  const checkUserExistForCategory = (data) => {
    const getData = usersData?.data?.userList?.find(
      (item) => item?.userID === data?.userID
    );
    return getData?.categoryList?.includes(categoryData?.name);
  };

  const addUserForCategory = (user) => {
    const workData = {
      ...user,
      // categoryList: user?.categoryList && user?.categoryList?.length > 0 ? [...user?.categoryList, categoryData?.name] : [categoryData?.name]
      categoryList: [categoryData?.name],
    };
    addUserForCategorys.mutate(workData);
  };

  const removeUser = (user) => {
    const workData = {
      userId: user?.userID,
      companyId: currentCompany,
      categoryId: categoryData?.categoryID,
    };
    removeUserFromCategory.mutate(workData);
  };

  const { data: usersData, refetch: userDatas } = useQuery({
    queryKey: ["usersList", currentCompany, searchUser, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_USERS_LIST,
        `pageNumber=1&selectedCompany=${currentCompany}&search=${searchUser}${tenantString}`
      ),
    enabled: !!currentCompany,
  });

  const { data: allUsersList } = useQuery({
    queryKey: ["usersList", searchUser],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_ALL_USERS_LIST,
        `pageNumber=1&search=${searchUser}`
      ),
  });

  // const updateUserForCategory = useMutation({
  //   mutationKey: ["updateUserForCategory"],
  //   mutationFn: (workData) => putWithQueryApiServices(apiRoutes.UPDATE_USER,
  //     `selectedCompany=${currentCompany}`
  //     , workData),
  //   onSuccess: () => {
  //     // companyListRefetch();
  //     // setCompanyForm(companyFormInitVal);
  //     userDatas();
  //     successToast("User Added For Category");
  //   },
  // });

  const addUserForCategorys = useMutation({
    mutationKey: ["addUserForCategory"],
    mutationFn: (workData) =>
      putWithQueryApiServices(
        apiRoutes.ADD_USER_FROM_CAT,
        `selectedCompany=${currentCompany}${tenantString}`,
        workData
      ),
    onSuccess: () => {
      userDatas();
      successToast("User Added For Category");
    },
  });

  const removeUserFromCategory = useMutation({
    mutationKey: ["updateCategoryform"],
    mutationFn: (workData) =>
      putWithQueryApiServices(
        apiRoutes.REMOVE_USER_FROM_CAT,
        `selectedCompany=${currentCompany}${tenantString}`,
        workData
      ),
    onSuccess: () => {
      userDatas();
      successToast("User Removed From Category");
    },
  });

  //   const { data, refetch: companyListRefetch } = useQuery({
  //     queryKey: ["companyLists"],
  //     queryFn: () =>
  //       getWithQueryApiServices(apiRoutes.GET_COMPANY_LIST, `pageNumber=1`),
  //   });

  return (
    <div
      style={{
        height: "80vh",
        // padding: "0 20px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          height: "92%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: "40%",
            // padding: "0 20px",
            // backgroundColor: "white",
            borderRadius: 14,
            boxSizing: "border-box",
            pointerEvents: selectedCategories?.length < 1 ? "none" : "auto",
            backgroundColor:
              selectedCategories?.length < 1 ? "#f0f0f0" : "white",
          }}
        >
          <StyledTypography style={{ paddingLeft: "20px" }}>
            Select Company
          </StyledTypography>
          <div
            style={{
              height: "60%",
              overflowY: "auto",
              marginTop: 20,
            }}
          >
            {filteredCompany?.map((item, i) => (
              <ListBox style={{ paddingLeft: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={companyForm?.some(
                        (val) => val?.companyID === item?.companyID
                      )}
                      onChange={() =>
                        // item?.companyID === companyForm?.companyID
                        //   ? handleCompanyCheckbox({})
                        //   : handleCompanyCheckbox(item)
                        handleCompanyCheckbox(item)
                      }
                    />
                  }
                  label={item?.name}
                />
              </ListBox>
            ))}
          </div>
        </div>

        <div
          style={{
            height: "55%",
            // padding: 20,
            boxSizing: "border-box",
            borderRadius: 14,
            pointerEvents: !addNewCategory ? "none" : "auto",
            backgroundColor: !addNewCategory ? "#f0f0f0" : "white",
          }}
        >
          <div style={{ paddingLeft: 20 }}>
            <StyledTypography>
              Add Users to Category {categoryData?.name}
            </StyledTypography>
            <CommonSearch
              style={{ width: "95%" }}
              label={"All Users"}
              value={searchUser}
              handleChange={(e) => setSearchUser(e.target.value)}
            />
          </div>
          <div
            style={{
              height: "55%",
              overflowY: "auto",
              marginTop: 20,
            }}
          >
            {categoryData?.categoryID ? (
              <>
                {allUsersList?.data?.userList?.map((user, i) => (
                  <ListBox
                    style={{
                      padding: 10,
                      justifyContent: "normal",
                      backgroundColor: checkUserExistForCategory(user)
                        ? "#deedf5"
                        : "white",
                      borderRadius: 6,
                      paddingLeft: 20,
                    }}
                  >
                    {checkUserExistForCategory(user) ? (
                      <IconButton
                        style={{
                          padding: 0,
                          marginRight: 20,
                        }}
                        onClick={() => removeUser(user)}
                      >
                        <PersonRemoveIcon
                          style={{
                            color: IconColor,
                            fontSize: 26,
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        style={{
                          padding: 0,
                          marginRight: 20,
                        }}
                        onClick={() => addUserForCategory(user)}
                      >
                        <GroupAddIcon
                          style={{
                            color: IconColor,
                            fontSize: 26,
                          }}
                        />
                      </IconButton>
                    )}

                    <Typography>{user?.username} </Typography>
                  </ListBox>
                ))}
              </>
            ) : (
              <StyledTypography style={{ textAlign: "center" }}>
                Select any category
              </StyledTypography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
