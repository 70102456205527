import { FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
// import moment from "moment";

export function CustomDatePicker(props) {
  const {
    label,
    onChange,
    value,
    name,
    isViewMode,
    maxDate,
    minDate,
    size,
    className,
    error,
    style,
    formik,
    disabled,
  } = props;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          name={name}
          label={label}
          value={value || null}
          size={"small"}
          // minDate={minDate && moment(minDate)}
          // maxDate={maxDate && moment(maxDate)}

          // sx={{
          //   height: "10px",
          //   width: "200px"
          // }}
          autoComplete="off"
          readOnly={isViewMode}
          disabled={disabled}
          slotProps={{ textField: { fullWidth: true, size: "small" } }}
          className={className}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              // {...params}
              // size={size || "small"}
              size="small"
              fullWidth
              autoComplete="off"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              sx={{
                height: "10px",
                width: "100%",
              }}
              error={
                error || (formik?.touched?.[name] && formik?.errors?.[name])
              }
            />
          )}
        />
      </LocalizationProvider>
      <FormHelperText error>
        {error || (formik?.touched?.[name] && formik?.errors?.[name])}
      </FormHelperText>
    </>
  );
}
