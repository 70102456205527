import styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Modal } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  getWithQueryApiServices,
  postWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { companyFormInitVal } from "../../../constants";
import { SplitContainer, StyledHeader } from "../../../styles/commonStyles";
import { IconColor } from "../../../styles/constants";
import { successToast } from "../../../utils/helper";
import { FullFeaturedCrudGrid } from "../../shared/CommonTable";
import { ParentPageWrapper } from "../../shared/ParentPageWrapper";
import PaymentForm from "../../shared/PaymentForm";
import { InvoiceForm } from "./InvoiceForm";

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1%",
  boxSizing: "border-box",
}));

const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  height: "100%",
}));

const ActionItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "36%",
  marginRight: 18,
}));

export const Invoice = () => {
  const { currentCompany, currentTenant, tenantString } = useOutletContext();
  const cookies = new Cookies();
  const userID = cookies.get("userId");
  const [companyForm, setCompanyForm] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [open, setOpen] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [permission, setPermission] = useState(false);

  // const handleCompanyCheckbox = (val) => {
  //   setCategoryData({ ...val });
  //   setAddNewCategory(val?.categoryID ? true : false);
  // };

  const columns = [
    {
      field: "companyID",
      headerName: "Invoiced to Company",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return companyData?.data?.list?.find((_) => _.companyID === params)
          ?.name;
      },
    },
    {
      field: "amount",
      headerName: "Invoice amount",
      align: "left",
      headerAlign: "left",
      editable: false,
      flex: 0.5,
      type: "number",
    },
    {
      field: "invoicedate",
      headerName: "Invoice Date",
      align: "left",
      headerAlign: "left",
      editable: false,
      flex: 0.6,
      type: "date",
      valueGetter: (params) => {
        if (new Date(params)) {
          return new Date(params);
        }
        return "";
      },
    },
    {
      field: "duedate",
      headerName: "Invoice Due Date",
      align: "left",
      headerAlign: "left",
      editable: false,
      flex: 0.6,
      type: "date",
      valueGetter: (params) => {
        if (new Date(params)) {
          return new Date(params);
        }
        return "";
      },
    },
    {
      field: "status",
      align: "center",
      headerName: "Status",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => {
        if (params.value === "unpaid") {
          return (
            <ActionContainer>
              <CloseIcon color="error" sx={{ fontSize: 20 }} />
            </ActionContainer>
          );
        } else {
          return (
            <ActionContainer>
              <CheckIcon color="success" sx={{ fontSize: 20 }} />
            </ActionContainer>
          );
        }
      },
    },
    {
      align: "center",
      headerAlign: "center",
      flex: 0.4,
      renderCell: (params) => {
        if (params?.row?.status === "unpaid") {
          return (
            <>
              <Button
                variant="contained"
                onClick={() => setOpen(params.row.invoiceID)}
              >
                Pay
              </Button>
              <Modal
                open={open === params.row.invoiceID}
                aria-labelledby="payment-modal"
                aria-describedby="payment-modal-description"
                onClose={() => setOpen(false)}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <h2 id="payment-modal">Payment</h2>
                  <PaymentForm
                    invoiceID={params.row.invoiceID}
                    onSubmit={(arg) => updateCategory.mutate(arg)}
                  />
                </Box>
              </Modal>
            </>
          );
        } else {
          return <VisibilityIcon sx={{ color: IconColor, fontSize: 20 }} />;
        }
      },
    },
  ];

  const { data: usersData, refetch: userDatas } = useQuery({
    queryKey: ["usersList", currentCompany, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_USERS_LIST,
        `pageNumber=1&selectedCompany=${currentCompany}${tenantString}`
      ),
    enabled: !!currentCompany,
  });

  const updateCategory = useMutation({
    mutationKey: ["payInvoiceform"],
    mutationFn: ({ invoiceID, formData }) =>
      postWithQueryApiServices(
        `${apiRoutes.PAY_INVOICE}/${invoiceID}`,
        `selectedCompany=${currentCompany}${tenantString}`,
        formData
      ),
    onSuccess: () => {
      // companyListRefetch();
      categoryListRefetch();
      setAddNewCategory(false);
      successToast("Invoice Updated Successfully");
    },
  });

  const handleCategoryCheckbox = (item) => {
    let copyValue = [...selectedCategories];

    if (copyValue.some((cat) => cat?.invoiceID === item?.invoiceID)) {
      copyValue = copyValue.filter((val) => val?.invoiceID !== item?.invoiceID);
    } else {
      copyValue = [item];
    }

    setSelectedCategories([...copyValue]);

    if (copyValue?.length === 1) {
      setCategoryData(copyValue?.[0]);
      setAddNewCategory(true);
    } else {
      setCategoryData({});
      setAddNewCategory(false);
    }
  };

  const { data: companyData, refetch: companyListRefetch } = useQuery({
    queryKey: ["companyLists"],
    queryFn: () =>
      getWithQueryApiServices(apiRoutes.GET_COMPANY_LIST, `pageNumber=1`),
  });

  const { data: invoiceData, refetch: categoryListRefetch } = useQuery({
    queryKey: ["invoiceLists", currentCompany, searchCategory, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_INVOICE_LIST,
        `pageNumber=1${
          tenantString ? "" : `&selectedCompany=${currentCompany}`
        }&search=${searchCategory}${tenantString}`
      ),
    enabled: !!currentCompany,
  });

  const handleAddNew = () => {
    setCategoryData({});
    setAddNewCategory(true);
  };
  useEffect(() => {
    if (currentCompany) {
      setAddNewCategory(false);
      setSelectedCategories([]);
      setCompanyForm([]);
      setSearchCategory("");
      setCategoryData({});
    }
  }, [currentCompany]);

  useEffect(() => {
    const currentUser = usersData?.data?.userList?.find(
      (item) => item.userID === userID
    );
    if (!!currentTenant) {
      setPermission(true);
    } else {
      setPermission(
        currentUser?.levelList?.includes("Edit Invoice") ? true : false
      );
    }
  }, [usersData, userID, currentTenant]);
  return (
    <ParentPageWrapper>
      <BoxContainer>
        <StyledHeader>Invoices</StyledHeader>
        <ParentContainer>
          <SplitContainer
            style={{ backgroundColor: "transparent", width: "60%" }}
          >
            <div style={{ height: "80vh" }}>
              <div
                style={{
                  backgroundColor: "white",
                  height: "92%",
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    height: "82%",
                  }}
                >
                  <FullFeaturedCrudGrid
                    initialRows={invoiceData?.data?.list}
                    columns={columns}
                    label="invoice"
                    handleAddNew={handleAddNew}
                    handleSelection={handleCategoryCheckbox}
                    search={searchCategory}
                    setSearch={setSearchCategory}
                    apiUrl={apiRoutes.CREATE_CATEGORY}
                    refetch={categoryListRefetch}
                    hideEdit={true}
                    initialFormValue={companyFormInitVal}
                    permission={permission}
                  ></FullFeaturedCrudGrid>
                </div>
              </div>
            </div>
          </SplitContainer>
          <SplitContainer
            style={{
              backgroundColor: "transparent",
              cursor: addNewCategory ? "auto" : "not-allowed",
            }}
          >
            <InvoiceForm
              categoryListRefetch={categoryListRefetch}
              companyListRefetch={companyListRefetch}
              companyForm={companyForm}
              setCompanyForm={setCompanyForm}
              categoryData={categoryData}
              setCategoryData={setCategoryData}
              setAddNewCategory={setAddNewCategory}
              addNewCategory={addNewCategory}
              setSelectedCategories={setSelectedCategories}
              permission={permission}
            />
          </SplitContainer>
        </ParentContainer>
      </BoxContainer>
    </ParentPageWrapper>
  );
};
