import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  deletewithQueryApiServices,
  getWithQueryApiServices,
  postWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { companyFormInitVal, expirationValues } from "../../../constants";
import { SplitContainer, StyledHeader } from "../../../styles/commonStyles";
import { errorToast, successToast } from "../../../utils/helper";
import { FullFeaturedCrudGrid } from "../../shared/CommonTable";
import { CustomSelectField } from "../../shared/CustomSelectField";
import { ParentPageWrapper } from "../../shared/ParentPageWrapper";
import { CategoryForm } from "./CategoryForm";
import { CategoryUser } from "./CategoryUser";

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1%",
  boxSizing: "border-box",
}));

const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
  marginLeft: "24px",
}));

const ActionItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "36%",
  marginRight: 18,
}));

export const Category = () => {
  const { currentCompany, tenantString, setCurrentCompany } =
    useOutletContext();
  const [companyForm, setCompanyForm] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [addNewCategory, setAddNewCategory] = useState(false);

  // const handleCompanyCheckbox = (val) => {
  //   setCategoryData({ ...val });
  //   setAddNewCategory(val?.categoryID ? true : false);
  // };

  const columns = [
    { field: "name", headerName: "Name", flex: 1, editable: true },
    {
      field: "expiration",
      headerName: "Expiration",
      align: "left",
      headerAlign: "left",
      editable: true,
      flex: 0.5,
      type: "singleSelect",
      valueGetter: (params) => {
        const selected = expirationValues?.find(
          (option) => option.value == params
        );
        return selected?.value || "";
      },
      valueOptions: expirationValues,
      getOptionValue: (value) => value.value,
      getOptionLabel: (value) => value.name,
    },
  ];

  const handleCategoryCheckbox = (item) => {
    let copyValue = [...selectedCategories];

    if (copyValue.some((cat) => cat?.categoryID === item?.categoryID)) {
      copyValue = copyValue.filter(
        (val) => val?.categoryID !== item?.categoryID
      );
    } else {
      copyValue = [item];
    }

    setSelectedCategories([...copyValue]);

    if (copyValue?.length === 1) {
      setCategoryData(copyValue?.[0]);
      setAddNewCategory(true);
    } else {
      setCategoryData({});
      setAddNewCategory(false);
    }
  };

  const submitCopyCategories = (category) => {
    if (companyForm?.length === 0) {
      return errorToast("Select any Categories and Companies");
    }

    let workData = {
      companies: companyForm?.map((item) => item?.companyID),
      categories: category?.map((item) => ({
        name: item?.name,
        expiration: item?.expiration,
      })),
    };

    updateCopyCategories.mutate(workData);
  };

  const { data: companyData, refetch: companyListRefetch } = useQuery({
    queryKey: ["companyLists", tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_COMPANY_LIST,
        `pageNumber=1${tenantString}`
      ),
  });

  const { data: categotyData, refetch: categoryListRefetch } = useQuery({
    queryKey: ["categoryLists", currentCompany, searchCategory, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_CATEGOTY_LIST,
        `pageNumber=1&selectedCompany=${currentCompany}&search=${searchCategory}${tenantString}`
      ),
    enabled: !!currentCompany,
  });

  const deleteCategory = useMutation({
    mutationKey: ["deleteCategoryDetails"],
    mutationFn: (categoryID) =>
      deletewithQueryApiServices(
        apiRoutes.DELETE_CATEGORY,
        `selectedCompany=${currentCompany}${tenantString}`,
        categoryID
      ),
    onSuccess: () => {
      // companyListRefetch();
      categoryListRefetch();
      setAddNewCategory(false);
      setCategoryData({});
      successToast("Category Deleted!");
    },
  });

  const updateCopyCategories = useMutation({
    mutationKey: ["updateCopyCategories"],
    mutationFn: (workData) =>
      postWithQueryApiServices(
        apiRoutes.COPY_CATEGORIES,
        `selectedCompany=${currentCompany}${tenantString}`,
        workData
      ),
    onSuccess: () => {
      // companyListRefetch();
      setAddNewCategory(false);
      categoryListRefetch();
      setSelectedCategories([]);
      setCompanyForm([]);
      successToast("Categories copied to respective companies");
    },
  });

  useEffect(() => {
    if (currentCompany) {
      setAddNewCategory(false);
      setSelectedCategories([]);
      setCompanyForm([]);
      setSearchCategory("");
      setCategoryData({});
    }
  }, [currentCompany]);

  return (
    <ParentPageWrapper>
      <BoxContainer>
        <StyledHeader>Categories</StyledHeader>
        <ParentContainer>
          <SplitContainer style={{ backgroundColor: "transparent" }}>
            <div style={{ height: "80vh" }}>
              <div
                style={{
                  backgroundColor: "white",
                  height: "92%",
                  borderRadius: 10,
                }}
              >
                {/* <ActionContainer>
                  <CommonSearch
                    value={searchCategory}
                    handleChange={(e) => setSearchCategory(e.target.value)}
                    style={{
                      margin: "2% 0 2% 3%",
                    }}
                  />
                  <Button
                    startIcon={<AddOutlinedIcon />}
                    variant="contained"
                    style={{ marginRight: "4%" }}
                  >
                    Add New Category
                  </Button>

                  <StyledTypography style={{ marginRight: "12%" }}>
                    Actions
                  </StyledTypography>
                </ActionContainer> */}
                <div
                  style={{
                    // overflowY: "auto",
                    height: "82%",
                  }}
                >
                  {!!tenantString && (
                    <div style={{ marginInline: 20, paddingTop: 25 }}>
                      <CustomSelectField
                        label="Current Company"
                        style={{ width: "100%" }}
                        name="currentCompany"
                        inputValues={companyData?.data?.list || []}
                        value={currentCompany || ""}
                        accept="companyID"
                        onChange={(e) => setCurrentCompany(e.target.value)}
                      />
                    </div>
                  )}

                  <FullFeaturedCrudGrid
                    initialRows={categotyData?.data?.list}
                    columns={columns}
                    label="category"
                    handleSelection={handleCategoryCheckbox}
                    search={searchCategory}
                    setSearch={setSearchCategory}
                    apiUrl={apiRoutes.CREATE_CATEGORY}
                    refetch={categoryListRefetch}
                    copyFun={submitCopyCategories}
                    initialFormValue={companyFormInitVal}
                  ></FullFeaturedCrudGrid>
                  {/* {categotyData?.data?.list?.length > 0 ? (
                  ) : (
                    <StyledTypography style={{ textAlign: "center" }}>
                      No Data Found
                    </StyledTypography>
                  )} */}
                </div>
              </div>
              {/* <Button
                variant="contained"
                style={{ position: "absolute", bottom: 0, marginLeft: "24px" }}
                onClick={submitCopyCategories}
              >
                Copy Categories
              </Button> */}
            </div>
          </SplitContainer>
          <SplitContainer
            style={{
              width: "30%",
              backgroundColor: "transparent",
              cursor: addNewCategory ? "auto" : "not-allowed",
            }}
          >
            <CategoryForm
              categoryListRefetch={categoryListRefetch}
              companyListRefetch={companyListRefetch}
              companyForm={companyForm}
              setCompanyForm={setCompanyForm}
              categoryData={categoryData}
              setCategoryData={setCategoryData}
              setAddNewCategory={setAddNewCategory}
              addNewCategory={addNewCategory}
              setSelectedCategories={setSelectedCategories}
            />
          </SplitContainer>
          <SplitContainer
            style={{ backgroundColor: "transparent", width: "30%" }}
          >
            <CategoryUser
              companyForm={companyForm}
              setCompanyForm={setCompanyForm}
              companyData={companyData}
              setAddNewCategory={setAddNewCategory}
              addNewCategory={addNewCategory}
              setCategoryData={setCategoryData}
              categoryData={categoryData}
              selectedCategories={selectedCategories}
            />
          </SplitContainer>
        </ParentContainer>
      </BoxContainer>
    </ParentPageWrapper>
  );
};
