import styled from "@emotion/styled";
import { Box, Button, Switch } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  getWithQueryApiServices,
  putWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { userFormInitVal } from "../../../constants";
import { SplitContainer, StyledHeader } from "../../../styles/commonStyles";
import { successToast } from "../../../utils/helper";
import { FullFeaturedCrudGrid } from "../../shared/CommonTable";
import { AccessList } from "./AccessList";

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1%",
  boxSizing: "border-box",
}));

const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
  marginLeft: "24px",
}));

const CompanyContainer = styled(Box)(({ theme }) => ({
  height: "700px",
  width: "100%",
  marginTop: 20,
  backgroundColor: "#f1f1f1",
  borderRadius: 14,
}));

export const User = () => {
  const cookies = new Cookies();
  const defaultcompany = cookies.get("defaultcompany");
  const [seelctedUser, setSelectedUser] = useState("");
  const [addNewUser, setAddnewUser] = useState(false);
  const { tenantString } = useOutletContext();
  const [searchUser, setSearchUser] = useState("");
  const [userDetails, setUserDetails] = useState(userFormInitVal);

  const { data: allUsersList, refetch: userListRefetch } = useQuery({
    queryKey: ["usersList", searchUser, tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_ALL_USERS_LIST,
        `pageNumber=1&search=${searchUser}${tenantString}`
      ),
  });

  const columns = [
    { field: "username", headerName: "User Name", flex: 0.8, editable: true },
    {
      field: "title",
      headerName: "Email",
      editable: true,
      flex: 1,
    },
    {
      field: "password",
      headerName: "Password",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            defaultChecked={params.value}
            onClick={() => updateUserPassword.mutate(params.row)}
            size="small"
          >
            Reset
          </Button>
        );
      },
    },

    {
      field: "ip",
      headerName: "Security Settings",
      flex: 0.8,
      editable: true,
    },
    {
      field: "issysadm",
      headerName: "Make System Admin",
      flex: 0.5,
      type: "boolean",
      renderCell: (params) => {
        return (
          <Switch
            defaultChecked={params.value}
            onChange={(e) =>
              updateUserDetails.mutate({
                ...params.row,
                issysadm: e.target.checked ? 1 : 0,
              })
            }
            size="small"
          />
        );
      },
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 0.5,
      type: "boolean",
      renderCell: (params) => {
        return (
          <Switch
            onChange={(e) =>
              updateUserDetails.mutate({
                ...params.row,
                isActive: e.target.checked ? 1 : 0,
              })
            }
            defaultChecked={params.value}
            size="small"
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (seelctedUser) {
      getWithQueryApiServices(
        `${apiRoutes.GET_USER_DETAILS_BY_ID}/${seelctedUser}`,
        `selectedCompany=${defaultcompany}${tenantString}`
      )
        .then(({ data }) => {
          setUserDetails(data);
          setAddnewUser(true);
        })
        .catch(() => {});
    }
  }, [seelctedUser, defaultcompany, tenantString]);
  const handleUserSelection = (item) => {
    setSelectedUser(item?.userID);
  };
  const createNewUser = (user) => {
    return { ...user, ...userFormInitVal };
  };
  const updateUser = (user) => {
    return { ...userDetails, ...user };
  };

  const updateUserPassword = useMutation({
    mutationKey: ["updateuserDetails"],
    mutationFn: (user) =>
      putWithQueryApiServices(
        apiRoutes.PASSWORD_RESET,
        `selectedCompany=${defaultcompany}${tenantString}`,
        user
      ),
    onSuccess: (data, type, value) => {
      userListRefetch();
      setAddnewUser(false);
      setSelectedUser("");
      setUserDetails(userFormInitVal);
      successToast("Mail sent with updated password");
    },
  });

  const updateUserDetails = useMutation({
    mutationKey: ["updateuserDetails"],
    mutationFn: (user) =>
      putWithQueryApiServices(
        apiRoutes.UPDATE_USER_BY_ID,
        `selectedCompany=${defaultcompany}${tenantString}`,
        user
      ),
    onSuccess: (data, type, value) => {
      userListRefetch();
      setAddnewUser(false);
      setSelectedUser("");
      setUserDetails(userFormInitVal);
      successToast("User Updated Successfully");
    },
  });

  return (
    <Box
      style={{ backgroundColor: "#7285b3", minHeight: "calc(100vh - 64px)" }}
    >
      <BoxContainer>
        <StyledHeader>User Settings and Permission</StyledHeader>
        <ParentContainer>
          <SplitContainer style={{ width: "100%", height: "600px" }}>
            {/* <ActionContainer>
              <CommonSearch
                value={searchUser}
                handleChange={(e) => setSearchUser(e.target.value)}
                style={{
                  margin: "2% 0",
                }}
              />
              <Button
                startIcon={<AddOutlinedIcon />}
                variant="contained"
                onClick={() => setAddnewUser(true)}
                style={{ marginRight: "4%" }}
              >
                Add New User
              </Button>
            </ActionContainer> */}

            <div
              style={{
                overflowY: "auto",
                height: "74%",
                // margin: "0 2%"
              }}
            >
              {allUsersList?.data?.userList?.length > 0 ? (
                <FullFeaturedCrudGrid
                  initialRows={allUsersList?.data?.userList}
                  columns={columns}
                  label="user"
                  handleSelection={handleUserSelection}
                  search={searchUser}
                  setSearch={setSearchUser}
                  apiUrl={apiRoutes.CREATE_USER}
                  refetch={userListRefetch}
                  addFunc={createNewUser}
                  editFunc={updateUser}
                  initialFormValue={userFormInitVal}
                ></FullFeaturedCrudGrid>
              ) : (
                <div>no data</div>
              )}

              {/* <RadioGroup value={seelctedUser}>
                {allUsersList?.data?.userList?.map((item) => (
                  <ListBox>
                    <FormControlLabel
                      value={item?.userID}
                      control={
                        <Radio onChange={() => setSelectedUser(item?.userID)} />
                      }
                      label={item?.username}
                      style={{ marginLeft: "12px" }}
                    />
                  </ListBox>
                ))}
              </RadioGroup> */}
            </div>
          </SplitContainer>
          {/* <SplitContainer
            style={{
              width: "46%",
              height: "600px",
              cursor: addNewUser ? "auto" : "not-allowed",
            }}
          >
            <UserForm
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              addNewUser={addNewUser}
              setAddnewUser={setAddnewUser}
              userListRefetch={userListRefetch}
              setSelectedUser={setSelectedUser}
              seelctedUser={seelctedUser}
            />
          </SplitContainer> */}
        </ParentContainer>
        <CompanyContainer
          style={{
            cursor: addNewUser ? "auto" : "not-allowed",
            display: userDetails?.userID ? "block" : "none",
          }}
        >
          <AccessList
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            addNewUser={addNewUser}
            setAddnewUser={setAddnewUser}
          />
        </CompanyContainer>
      </BoxContainer>
    </Box>
  );
};
