import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  getWithQueryApiServices,
  postWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { filerSearchInit } from "../../../constants";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { ListBox } from "../../../styles/commonStyles";
import { errorToast } from "../../../utils/helper";
import { CommonSearch } from "../../shared/CommonSearch";
import { CustomTextField } from "../../shared/CustomTextField";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
    position: "none",
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Container = styled("div")(({ theme }) => ({
  height: "90vh",
  width: "100%",
  backgroundColor: "#deedf5",
  padding: 20,
  boxSizing: "border-box",
  position: "relative",
}));

const DateBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "30px",
}));

const CompanyBox = styled(Box)(({ theme }) => ({
  height: "62vh",
  width: "300px",
  backgroundColor: "white",
  borderRadius: 8,
  paddingTop: 12,
  boxSizing: "border-box",
  overflowY: "auto",
}));
const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  marginRight: 16,
}));

export const DateFilter = ({ searchValue, setSearchValue, setOpen }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const defaultcompany = cookies.get("defaultcompany");
  const defaulttenant = cookies.get("defaulttenant");
  let tenantString = "";
  if (defaulttenant) {
    tenantString = `&defaulttenant=${defaulttenant}`;
  }
  const [values, setValues] = useState(filerSearchInit);
  // const { tenantString } = useOutletContext();

  const [lockCategories, setLockCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState({});

  const [searchText, setSearchText] = useState(searchValue);
  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCompanyCheckBox = (companyId) => {
    let copyValue = [...values?.selectedCompanies];
    const isCompanyExist = copyValue?.some((id) => id === companyId);

    if (isCompanyExist) {
      copyValue = copyValue?.filter((item) => item !== companyId);
    } else {
      copyValue.push(companyId);
    }
    setValues({ ...values, selectedCompanies: copyValue });

    if (selectedCategories?.[companyId]) {
      let categories = { ...selectedCategories };
      delete categories?.[companyId];
      setSelectedCategories({ ...categories });
    }
  };

  const isCategoryLocked = (category, value) => {
    let copyValue = { ...selectedCategories };

    values?.selectedCompanies?.forEach((companyId) => {
      const isCatExistForCompany =
        groupByCategory?.[companyId]?.categories?.includes(category);
      if (isCatExistForCompany) {
        if (copyValue?.[companyId]) {
          const isCatAlreadyExist = copyValue?.[companyId]?.some(
            (item) => item === category
          );
          let removeDuplicates = [];
          if (value) {
            if (!isCatAlreadyExist) {
              removeDuplicates = [...copyValue?.[companyId], category];
            } else {
              removeDuplicates = [...copyValue?.[companyId]];
            }
          } else {
            if (isCatAlreadyExist) {
              removeDuplicates = copyValue?.[companyId]?.filter(
                (item) => item !== category
              );
            } else {
              removeDuplicates = [...copyValue?.[companyId]];
            }
          }

          copyValue = {
            ...copyValue,
            [companyId]: removeDuplicates,
          };
        } else {
          if (value) {
            copyValue = {
              ...copyValue,
              [companyId]: [category],
            };
          }
        }
      }
    });

    setSelectedCategories({
      ...copyValue,
    });
  };

  const categoryOnchange = (companyId, category, value) => {
    if (lockCategories) {
      return isCategoryLocked(category, value);
    }
    let copyValue = { ...selectedCategories };

    if (copyValue?.[companyId]) {
      let removeDuplicates = copyValue?.[companyId]?.some(
        (item) => item === category
      )
        ? copyValue?.[companyId]?.filter((item) => item !== category)
        : [...copyValue?.[companyId], category];

      copyValue = {
        ...copyValue,
        [companyId]: removeDuplicates,
      };
    } else {
      copyValue = {
        ...copyValue,
        [companyId]: [category],
      };
    }

    setSelectedCategories({
      ...copyValue,
    });
  };
  const clearSearch = () => {
    setSearchText("");
    setSearchValue("");
  };
  const handleSubmit = () => {
    let allCat = [];
    Object.values(selectedCategories)?.forEach((item) => {
      allCat = [...allCat, ...item];
    });

    if (!searchText) {
      return errorToast("Enter any values in search box");
    }
    if (
      values?.isDocumentDate &&
      (!values?.documentDateFrom || !values?.documentDateTo)
    ) {
      return errorToast("Select Documents From and To date");
    }
    if (
      values?.isScannedDate &&
      (!values?.scannedDateFrom || !values?.scannedDateTo)
    ) {
      return errorToast("Select Scanned From and To date");
    }
    if (values?.selectedCompanies?.length === 0 || allCat?.length === 0) {
      return errorToast("Select any Company/Categories");
    }

    let workData = {
      searchQuery: searchText,
      selectedCompanies: values?.selectedCompanies,
      selectedCategories: [...new Set([...allCat])],
    };

    if (
      values?.isDocumentDate &&
      values?.documentDateFrom &&
      values?.documentDateTo
    ) {
      workData.documentDateFrom = values?.documentDateFrom;
      workData.documentDateTo = values?.documentDateTo;
    }
    if (
      values?.isScannedDate &&
      values?.scannedDateFrom &&
      values?.scannedDateTo
    ) {
      workData.scannedDateFrom = values?.scannedDateFrom;
      workData.scannedDateTo = values?.scannedDateTo;
    }

    setSearchValue(searchText);
    getPowerSearch.mutate(workData);
  };

  const getPowerSearch = useMutation({
    mutationKey: ["powerSearch"],
    mutationFn: (workData) =>
      postWithQueryApiServices(
        apiRoutes.POWER_SEARCH,
        `selectedCompany=${defaultcompany}${tenantString}`,
        workData
      ),
    onSuccess: ({ data }) => {
      navigate(ROUTE_PATHS.SEARCH_RESULT, {
        state: { data, searchValue: searchText },
      });
      setOpen && setOpen(false);
      // successToast("User Created Successfully");
    },
  });

  const { data: allCompaniesWithCategory } = useQuery({
    queryKey: ["allUsersWithCategory"],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_ALL_USER_WITH_CATEGORY,
        `pageNumber=1&selectedCompany=${defaultcompany}${tenantString}`
      ),
  });

  const groupByCategory = allCompaniesWithCategory?.data?.reduce((obj, cat) => {
    const { companyID } = cat;
    obj[companyID] = cat;
    return obj;
  }, {});

  const isCategoryChecked = (companyId, category) => {
    const val = selectedCategories?.[companyId]?.includes(category);
    return val ? true : false;
  };

  const handleMasterScroll = async (e, index) => {
    if (lockCategories) {
      let combineScroll = document.querySelectorAll(".combineScroll");
      let master = e.target;
      let height = master.scrollHeight - master.clientHeight;
      let percentage = (master.scrollTop / height) * 100;

      for (let i = 0; i < combineScroll.length; i++) {
        let slaveHeight =
          combineScroll[i].scrollHeight - combineScroll[i].clientHeight;
        let newSlavePosition = Math.ceil((percentage / 100) * slaveHeight);
        if (i !== index) {
          combineScroll[i].scroll({
            top: newSlavePosition,
          });
        }
      }
    }
  };

  return (
    <Container>
      <IconButton
        style={{ position: "absolute", right: 20, top: 14 }}
        onClick={() => setOpen && setOpen(false)}
      >
        <CloseIcon style={{ fontSize: "26px" }} />
      </IconButton>
      <Grid container>
        <Grid item container xs={12}>
          <Grid item xs={3}></Grid>
          <Grid item xs={4}>
            <CustomTextField
              sx={{ mb: 2 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              name="searchValue"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
              startAdornment={<SearchIcon style={{ color: "#bdbdbd" }} />}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={clearSearch}
                  >
                    {searchText && <StyledCancelIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={4}>
          <DateBox>
            <Switch
              name="isDocumentDate"
              value={values?.isDocumentDate}
              onChange={(e) => {
                setValues({
                  ...values,
                  [e.target.name]: e.target.checked,
                  documentDateFrom: "",
                  documentDateTo: "",
                });
              }}
            />
            Documents Date From :
            <CustomTextField
              type="date"
              disabled={!values?.isDocumentDate}
              style={{ width: "160px", marginLeft: "3%" }}
              name="documentDateFrom"
              value={values?.documentDateFrom}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </DateBox>
          <DateBox>
            <Switch
              name="isScannedDate"
              value={values?.isScannedDate}
              onChange={(e) => {
                setValues({
                  ...values,
                  [e.target.name]: e.target.checked,
                  scannedDateFrom: "",
                  scannedDateTo: "",
                });
              }}
            />
            Scanned Date From :
            <CustomTextField
              type="date"
              disabled={!values?.isScannedDate}
              style={{ width: "160px", marginLeft: "7%" }}
              name="scannedDateFrom"
              value={values?.scannedDateFrom}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </DateBox>
        </Grid>
        <Grid item xs={2}>
          <DateBox>
            To :
            <CustomTextField
              type="date"
              disabled={!values?.isDocumentDate}
              style={{ width: "160px", marginLeft: 8 }}
              name="documentDateTo"
              value={values?.documentDateTo}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </DateBox>
          <DateBox>
            To :
            <CustomTextField
              type="date"
              disabled={!values?.isScannedDate}
              style={{ width: "160px", marginLeft: 8 }}
              name="scannedDateTo"
              value={values?.scannedDateTo}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </DateBox>
        </Grid>
        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            // style={{ position: "absolute", bottom: 35 }}
            onClick={handleSubmit}
          >
            {"Search"}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ marginBottom: 8, fontWeight: "bold" }}>
            Companies
          </Typography>
          <CompanyBox>
            <div style={{}}>
              <CommonSearch
                style={{ width: "100%", border: "none", marginBottom: 8 }}
                label={" "}
                // value={searchUser}
                // handleChange={(e) => setSearchUser(e.target.value)}
              />
              {allCompaniesWithCategory?.data?.map((item) => (
                <ListBox>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values?.selectedCompanies?.some(
                          (id) => id === item?.companyID
                        )}
                        onChange={() => handleCompanyCheckBox(item?.companyID)}
                      />
                    }
                    label={item?.name}
                    style={{ paddingLeft: "18px" }}
                  />
                </ListBox>
              ))}
            </div>
          </CompanyBox>
        </Grid>

        <Grid item xs={9}>
          <div style={{ display: "flex" }}>
            <Typography style={{ marginBottom: 8, fontWeight: "bold" }}>
              Categories
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={lockCategories}
                  onChange={() => {
                    setLockCategories(!lockCategories);
                  }}
                />
              }
              label={"Lock Categories"}
              style={{
                margin: 0,
                padding: "0",
                paddingLeft: "24px",
                marginTop: -8,
              }}
            />
          </div>
          <div style={{ position: "relative", paddingLeft: 20 }}>
            {values?.selectedCompanies?.length ? (
              <Carousel responsive={responsive}>
                {values?.selectedCompanies?.map((id, index) => (
                  <CompanyBox
                    onScroll={(e) => handleMasterScroll(e, index)}
                    className="combineScroll"
                  >
                    <CommonSearch
                      style={{
                        width: "94%",
                        border: "none",
                        marginBottom: 8,
                        boxSizing: "border-box",
                      }}
                      label={groupByCategory?.[id]?.name || ""}
                      // value={searchUser}
                      // handleChange={(e) => setSearchUser(e.target.value)}
                    />

                    {groupByCategory?.[id]?.categories?.map((category) => (
                      <ListBox>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCategoryChecked(id, category)}
                              onChange={(e) =>
                                categoryOnchange(id, category, e.target.checked)
                              }
                            />
                          }
                          label={category}
                          style={{ paddingLeft: "18px" }}
                        />
                      </ListBox>
                    ))}
                  </CompanyBox>
                ))}
              </Carousel>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40vh",
                }}
              >
                <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                  Select any Company
                </Typography>
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
