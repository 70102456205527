import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  getWithQueryApiServices,
  postWithQueryApiServices,
  putWithQueryApiServices,
} from "../../../apis/api";
import { apiRoutes } from "../../../apis/apiPath";
import { invoiceFormInitVal } from "../../../constants";
import { StyledTypography } from "../../../styles/commonStyles";
import { errorToast, successToast } from "../../../utils/helper";
import { FullFeaturedCrudGrid } from "../../shared/CommonTable";
import { CustomDatePicker } from "../../shared/CustomDatePicker";
import { CustomSelectField } from "../../shared/CustomSelectField";
import { CustomTextField } from "../../shared/CustomTextField";

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#bdbdbd",
  height: 26,
  margin: "0 4px 0 12px",
}));

const CriteriaOptBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#deedf5",
  boxSizing: "border-box",
  padding: "10px 0",
  margin: "12px 0",
  borderRadius: 20,
  paddingLeft: 6,
}));

const columns = [
  { field: "item", headerName: "Item / Service", flex: 1, editable: true },
  {
    field: "quantity",
    headerName: "Quantity",
    align: "left",
    headerAlign: "left",
    editable: true,
    flex: 0.5,
    type: "number",
  },
  {
    field: "price",
    headerName: "Price",
    align: "left",
    headerAlign: "left",
    editable: true,
    flex: 0.5,
    type: "number",
  },
  {
    field: "recurring",
    headerName: "Recurring",
    align: "left",
    headerAlign: "left",
    editable: true,
    flex: 0.5,
    type: "boolean",
  },
];
export const InvoiceForm = ({
  categoryListRefetch,
  companyListRefetch,
  companyForm,
  setCompanyForm,
  categoryData,
  setCategoryData,
  addNewCategory,
  setAddNewCategory,
  setSelectedCategories,
  permission,
}) => {
  const [categoryValues, setCategoryValues] = useState(invoiceFormInitVal);
  const [criteriaValues, setCriteriaValues] = useState([]);

  const { currentCompany, tenantString } = useOutletContext();

  const formHandleChange = (name, value) => {
    setCategoryValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sumbitCompanyForm = () => {
    const fieldCheck = Object.values(categoryValues)?.every(
      (val) => val !== ""
    );
    if (!fieldCheck) {
      return errorToast("Please fill all the fields");
    }

    if (categoryData?.invoiceID) {
      updateCategory.mutate();
    } else {
      createCategory.mutate();
    }
  };

  const createCategory = useMutation({
    mutationKey: ["createInvoiceform"],
    mutationFn: () =>
      postWithQueryApiServices(
        apiRoutes.GET_INVOICE_LIST,
        `selectedCompany=${currentCompany}${tenantString}`,
        { ...categoryValues, invoiceitems: criteriaValues }
      ),
    onSuccess: ({ data }) => {
      setAddNewCategory(false);
      categoryListRefetch();
      successToast("Category added Successfully");
      setCategoryValues(invoiceFormInitVal);
      setSelectedCategories([]);
      setCategoryData({});
    },
  });

  const updateCategory = useMutation({
    mutationKey: ["updateInvoiceform"],
    mutationFn: () =>
      putWithQueryApiServices(
        apiRoutes.GET_INVOICE_LIST,
        `selectedCompany=${currentCompany}${tenantString}`,
        { ...categoryValues, invoiceitems: criteriaValues }
      ),
    onSuccess: () => {
      categoryListRefetch();
      setAddNewCategory(false);
      successToast("Category Updated Successfully");
      setCategoryValues(invoiceFormInitVal);
      setCategoryData({});
      setSelectedCategories([]);
    },
  });

  const rowUpdateFun = (newRow) => {
    if (newRow.isNew) {
      setCriteriaValues((prev) => [
        ...prev,
        { ...newRow, isNew: false, invoiceitemID: newRow.id },
      ]);
    } else {
      const updatedRow = [...criteriaValues].map((item) => {
        if (item.invoiceitemID === newRow.invoiceitemID) {
          return { ...newRow };
        } else {
          return item;
        }
      });

      setCriteriaValues(updatedRow);
    }
  };
  const { data: companyList } = useQuery({
    queryKey: ["companyLists"],
    queryFn: () =>
      getWithQueryApiServices(apiRoutes.GET_COMPANY_LIST, `pageNumber=1`),
  });

  useEffect(() => {
    if (categoryData?.invoiceID) {
      setCategoryValues({
        ...categoryData,
        duedate: new Date(categoryData.duedate),
        invoicedate: new Date(categoryData.invoicedate),
      });

      if (categoryData?.invoiceitems?.length > 0) {
        setCriteriaValues(JSON.parse(categoryData?.invoiceitems));
      } else {
        setCriteriaValues([]);
      }
    } else {
      setCategoryValues(invoiceFormInitVal);
      setCriteriaValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData?.invoiceID]);

  useEffect(() => {
    let amount = criteriaValues?.reduce((sum, obj) => {
      return sum + parseInt(obj.quantity) * parseInt(obj.price);
    }, 0);
    let recurring_amount = criteriaValues?.reduce((sum, obj) => {
      if (obj.recurring) {
        return sum + parseInt(obj.quantity) * parseInt(obj.price);
      } else {
        return sum;
      }
    }, 0);
    if (categoryValues?.tax) {
      amount = parseInt(amount * (1 + categoryValues?.tax / 100));
      recurring_amount = parseInt(
        recurring_amount * (1 + categoryValues?.tax / 100)
      );
    }
    if (categoryValues?.shippingcost) {
      amount = parseInt(amount) + parseInt(categoryValues?.shippingcost);
      if (recurring_amount) {
        recurring_amount =
          parseInt(recurring_amount) + parseInt(categoryValues?.shippingcost);
      }
    }
    formHandleChange("amount", amount);
    formHandleChange("recurring_amount", recurring_amount);
  }, [criteriaValues, categoryValues?.tax, categoryValues?.shippingcost]);
  return (
    <div
      style={{
        maxHeight: "80vh",
        height: "80vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: "92%",
          borderRadius: 10,
          // padding: "2px 40px",
          boxSizing: "border-box",
          position: "relative",
          pointerEvents: !addNewCategory ? "none" : "auto",
          backgroundColor: !addNewCategory ? "#f0f0f0" : "white",
        }}
      >
        <div
          style={{
            minHeight: "85%",
            maxHeight: "85%",
            overflowY: "auto",
            opacity: !addNewCategory ? "0.6" : "1",
            borderRadius: 10,
          }}
        >
          <FullFeaturedCrudGrid
            hideFooterPagination={true}
            initialRows={criteriaValues}
            delFun={setCriteriaValues}
            columns={columns}
            label="invoiceitem"
            rowUpdateFun={rowUpdateFun}
            handleSelection={() => {}}
            refetch={categoryListRefetch}
            permission={permission}
          ></FullFeaturedCrudGrid>
          <div
            style={{
              padding: "2px 40px",
            }}
          >
            <StyledTypography></StyledTypography>
            <CustomSelectField
              label="Invoiced to Company"
              style={{ width: "100%" }}
              name="companyID"
              accept="companyID"
              inputValues={companyList?.data.list || []}
              value={categoryValues?.companyID}
              onChange={(e) => formHandleChange(e.target.name, e.target.value)}
            />
            <StyledTypography></StyledTypography>
            <CustomTextField
              label="Tax %"
              name="tax"
              value={categoryValues?.tax}
              onChange={(e) => formHandleChange(e.target.name, e.target.value)}
            />
            <StyledTypography></StyledTypography>
            <CustomDatePicker
              label="Invoice Date"
              name="invoicedate"
              value={moment(categoryValues?.invoicedate)}
              onChange={(value) => formHandleChange("invoicedate", value)}
            />
            <StyledTypography></StyledTypography>
            <CustomDatePicker
              label="Invoice Due Date"
              name="duedate"
              value={moment(categoryValues?.duedate)}
              onChange={(value) => formHandleChange("duedate", value)}
            />
            <StyledTypography></StyledTypography>
            <CustomTextField
              label="Shiping Cost"
              name="shippingcost"
              value={categoryValues?.shippingcost}
              onChange={(e) => formHandleChange(e.target.name, e.target.value)}
            />
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                margin: "15px 0",
                fontWeight: 600,
              }}
            >
              Total : <span>{categoryValues?.amount}</span>
            </div>
          </div>
        </div>
        {permission && (
          <div
            style={{
              height: "12%",
              position: "absolute",
              bottom: 0,
              paddingLeft: "40px",
              opacity: !addNewCategory ? "0.6" : "1",
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: 20 }}
              onClick={sumbitCompanyForm}
            >
              {categoryData?.categoryID ? "Update" : "Save"}
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                setCategoryValues(invoiceFormInitVal);
                setCriteriaValues([]);
                setCategoryData({});
                setAddNewCategory(false);
                setSelectedCategories([]);
                setCompanyForm([]);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
