import React from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTE_PATHS } from "../routes/routePath";
import { routes } from "../routes/routes";
import { AdminSingin } from "./pages/login/AdminSingin";
import { Singin } from "./pages/login/Singin";
import ProtectedRoute from "./pages/login/protectedRoute";
import { Singup } from "./pages/signup/Singup";
import SubscriptionChoices from "./pages/subscribe/Subscribe";

export const Root = () => {
  const routeMapping = (path, Component, exact, nestedRoutes, index) => {
    return (
      <Route path={path} element={<Component />} exact={exact} key={index}>
        {nestedRoutes &&
          nestedRoutes.map(({ path, Component, exact, nestedRoutes }, index) =>
            routeMapping(path, Component, exact, nestedRoutes, index)
          )}
      </Route>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<Singin />} />
      <Route path={ROUTE_PATHS.ADMIN_LOGIN} element={<AdminSingin />} />
      <Route path={ROUTE_PATHS.SIGNUP} element={<Singup />} />
      <Route path={ROUTE_PATHS.SUBSCRIBE} element={<SubscriptionChoices />} />
      <Route path="/" element={<ProtectedRoute />}>
        {routes.map(({ path, Component, exact, nestedRoutes }, index) =>
          routeMapping(path, Component, exact, nestedRoutes, index)
        )}
      </Route>
    </Routes>
  );
};
