import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { useWindowHeight } from "@react-hook/window-size";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import { getWithQueryApiServices } from "../../apis/api";
import { apiRoutes } from "../../apis/apiPath";
import Navbar from "./Navbar";

const MainLayout = styled("div")(({ height }) => ({
  //   paddingTop: theme.spacing(8),
  //   height: height - 64,
}));

const BodyContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
}));

const StyledLayout = styled(Box)(() => ({
  overflow: "auto",
  height: "100%",
  width: "100%",
}));

export function Layout() {
  const height = useWindowHeight();
  const cookies = new Cookies();
  const defaultcompany = cookies.get("defaultcompany");
  const defaulttenant = cookies.get("defaulttenant");

  const [currentTenant, setCurrentTenant] = React.useState(defaulttenant || "");
  const [currentCompany, setCurrentCompany] = React.useState(
    defaultcompany || ""
  );
  const [tenantString, setTenantString] = React.useState(
    defaulttenant ? `&defaulttenant=${defaulttenant}` : ""
  );

  const { data, refetch: globalCompanyRefetch } = useQuery({
    queryKey: ["companyLists", tenantString],
    queryFn: () =>
      getWithQueryApiServices(
        apiRoutes.GET_COMPANY_LIST,
        `pageNumber=1${tenantString}`
      ),
  });

  const { data: tenants, refetch: globalTenantRefetch } = useQuery({
    queryKey: ["tenentLists", currentTenant],
    queryFn: () =>
      getWithQueryApiServices(apiRoutes.GET_TENENT_LIST, `pageNumber=1`),
    enabled: !!defaulttenant,
  });
  useEffect(() => {
    if (currentTenant) setTenantString(`&defaulttenant=${currentTenant}`);
  }, [currentTenant]);

  return (
    <MainLayout height={height}>
      <Navbar
        currentCompany={currentCompany}
        setCurrentCompany={setCurrentCompany}
        companyList={data?.data?.list}
        currentTenant={currentTenant}
        setCurrentTenant={setCurrentTenant}
        tenantList={tenants?.data?.list}
        globalCompanyRefetch={globalCompanyRefetch}
      />
      <BodyContainer>
        <StyledLayout>
          <Outlet
            context={{
              currentCompany,
              currentTenant,
              tenantString,
              setCurrentCompany,
              globalCompanyRefetch,
            }}
          />
        </StyledLayout>
      </BodyContainer>
    </MainLayout>
  );
}
