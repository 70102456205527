import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import CopyAll from "@mui/icons-material/CopyAll";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import {
  deletewithQueryApiServices,
  postWithQueryApiServices,
  putWithQueryApiServices,
} from "../../apis/api";
import { errorToast, successToast } from "../../utils/helper";
import { CommonSearch } from "./CommonSearch";

const ActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  borderBottom: "1px solid",
  borderColor: "rgb(224 224 224)",
  flex: 1,
}));

function EditToolbar(props) {
  const {
    setRows,
    setRowModesModel,
    label,
    search,
    setSearch,
    columns,
    initialFormValue,
    handleAddNew,
    permission,
  } = props;

  const handleClick = () => {
    if (handleAddNew) {
      handleAddNew();
      return;
    }
    const id = randomId();
    // const additionField = addFunc ? addFunc({}) : {};
    let newRow = {
      ...initialFormValue,
      id,
      isNew: true,
    };
    setRows((oldRows) => [newRow, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].name },
    }));
  };

  return (
    <GridToolbarContainer style={{}}>
      <ActionContainer>
        {setSearch && (
          <CommonSearch
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
          />
        )}
        <Box sx={{ flexGrow: 1 }} />
        {permission && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add New {label}
          </Button>
        )}
      </ActionContainer>
    </GridToolbarContainer>
  );
}
export const FullFeaturedCrudGrid = ({
  initialRows,
  columns,
  label,
  handleSelection,
  search,
  setSearch,
  apiUrl,
  refetch,
  copyFun,
  addFunc,
  editFunc,
  initialFormValue,
  hideFooterPagination,
  rowUpdateFun,
  delFun,
  handleAddNew,
  hideEdit,
  permission = true,
}) => {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const { currentCompany, tenantString } = useOutletContext();

  const handleRowEditStop = (params, event) => {
    // console.log({ params, event });
    // processRowUpdate()
    // if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    //   event.defaultMuiPrevented = true;
    // }
  };

  useEffect(() => {
    if (initialRows !== undefined) {
      setRows([
        ...initialRows?.map((obj) => ({
          ...obj,
          id: obj[`${label.toLowerCase()}ID`],
        })),
      ]);
    }
  }, [initialRows, label]);
  const handleEditClick = (id) => () => {
    const selectedRow = rows.find((_) => _.id === id);

    handleSelection(selectedRow);
    // const newRow = editFunc ? editFunc(selectedRow) : {};
    // setRows([...rows.filter((row) => row.id !== id), newRow]);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCopyClick = (id) => () => {
    copyFun(rows.filter((_) => _.id === id));
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    if (delFun) delFun(rows.filter((row) => row.id !== id));
    if (apiUrl) deleteApiCall.mutate(id);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    if (rowUpdateFun) {
      rowUpdateFun(newRow);
      return { ...newRow, isNew: false };
    }
    // const updatedRow = editFunc
    //   ? editFunc({ ...newRow, isNew: false })
    //   : { ...newRow, isNew: false };
    const updatedRow = { ...newRow, isNew: false };
    const columnNames = columns.map((obj) => obj.field);
    const fieldCheck = columnNames.every((key) => {
      return newRow[key] !== undefined && newRow[key] !== "";
    });
    if (!fieldCheck) {
      return errorToast("Please fill all the fields");
    }

    if (newRow?.[`${label.toLowerCase()}ID`]) {
      const mergedRow = editFunc ? editFunc(updatedRow) : updatedRow;
      if (apiUrl) updateApiCall.mutate(mergedRow);
    } else {
      if (apiUrl) createApiCall.mutate(updatedRow);
    }
    return updatedRow;
  };
  const updateApiCall = useMutation({
    mutationKey: [`update${label}form`],
    mutationFn: (newRow) =>
      putWithQueryApiServices(
        apiUrl,
        `selectedCompany=${currentCompany}${tenantString}`,
        newRow
      ),
    onSuccess: () => {
      refetch();
    },
  });
  const createApiCall = useMutation({
    mutationKey: [`create${label}form`],
    mutationFn: (newRow) =>
      postWithQueryApiServices(
        apiUrl,
        `selectedCompany=${currentCompany}${tenantString}`,
        newRow
      ),
    onSuccess: ({ data }) => {
      refetch();
    },
  });

  const deleteApiCall = useMutation({
    mutationKey: [`delete${label}Details`],
    mutationFn: (id) =>
      deletewithQueryApiServices(
        apiUrl,
        `selectedCompany=${currentCompany}${tenantString}`,
        id
      ),
    onSuccess: () => {
      refetch();
      successToast("Category Deleted!");
    },
  });

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowClick = (params) => {
    handleSelection(params.row);
  };
  const actionColumn = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const editButton = (
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        );
        const copyButton = (
          <GridActionsCellItem
            icon={<CopyAll />}
            label="Copy"
            onClick={handleCopyClick(id)}
            color="inherit"
          />
        );
        const deleteButton = (
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />
        );
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        if (copyFun) {
          return [copyButton, deleteButton];
        }
        return [deleteButton];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={[...columns, ...(permission ? actionColumn : [])]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setRows,
            setRowModesModel,
            label,
            search,
            setSearch,
            columns,
            initialFormValue,
            handleAddNew,
            permission,
          },
        }}
        onRowClick={handleRowClick}
        hideFooter={hideFooterPagination}
        sx={{ fontSize: "1rem" }}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};
